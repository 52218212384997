import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { SelectContractComponent } from '@app-components/select-contract/select-contract.component';
import { RoleEnum } from '@app-graphql';
import { ContractService } from '@app-services/contract/contract.service';
import { firstValueFrom } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class RoleGuard  {
    constructor(
        private contractsService: ContractService,
        private router: Router,
    ) {
    }

    public async canActivate(
        route: ActivatedRouteSnapshot,
    ): Promise<boolean | UrlTree> {
        const roles = route.data.roles as Array<RoleEnum>;
        if (! roles) {
            return true;
        }
        const currentRole = await firstValueFrom(this.contractsService.getContractRole().pipe(
            filter((role) => !! role),
        ));

        return roles.includes(currentRole) ? true
            : this.router.navigateByUrl(SelectContractComponent.getStartingRoute(currentRole));
    }

}
