import { Component, OnInit } from '@angular/core';
import { environment } from '@app-environments/environment';

@Component({
    selector: 'app-layout-auth',
    templateUrl: './layout-auth.component.html',
    styleUrls: ['./layout-auth.component.scss'],
})
export class LayoutAuthComponent implements OnInit {
    public isSirWinston = false;

    public ngOnInit() {
        //TODO: Use a feature flag, This is a temporary solution
        this.isSirWinston = ! environment.production
            ? true
            : window.location.hostname === 'app.sir-winston.applicationcasinoentry.nl';

    }
}
