import { Component, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';

@Component({
    template: '',
})
export class UnsubscribingComponent implements OnDestroy {
    public subscriptions: Array<Subscription> = [];
    public ngDestroy$ = new Subject<void>();

    public ngOnDestroy() {
        this.ngDestroy$.next();
        this.ngDestroy$.complete();
        this.subscriptions.forEach((s) => s.unsubscribe());
    }
}
