const plugin = require('tailwindcss/plugin');
const flattenTheme = require('./flatten-theme');

const webkitTextFill = plugin(({ addUtilities, variants, theme, e }) => {
    const flattenColors = flattenTheme(theme('colors'));
    const utilities = Object.entries(flattenColors).reduce((acc, [key, value]) => Object.assign(acc, {
        [`.${e(`webkit-text-fill-${key}`)}`]: {
            '-webkit-text-fill-color': value,
        },
    }), {});
    addUtilities(utilities, variants('webkitTextFill'));
}, {
    variants: { webkitTextFill: [] },
});

module.exports = webkitTextFill;
