<div class="pb-16">
    <div *ngFor="let fontWeight of fontWeights | keyvalue"
         class="my-8 grid grid-cols-6 items-center"
    >
        <div>
            <pre class="whitespace-normal">{{ 'text&#8209;' + fontName }} {{ 'font&#8209;' + fontWeight.key }}</pre>
        </div>
        <div class="col-span-5"
             [style]="{'font-size': fontGroup[0], 'line-height': fontGroup[1].lineHeight, 'font-weight': fontWeight.value }"
        >
            The quick brown dog jumps over the lazy fox
        </div>
    </div>
</div>
