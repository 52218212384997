import { Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client/core';
import {
    IgnoreVisitorTaskMutationService,
    NewVisitorTaskSubscriptionService,
    IgnoreVisitorTaskMutationVariables,
    VisitorTasksQueryService,
    VisitorTaskStatusEnum,
    VisitorTaskUpdatedSubscriptionService,
    FinishVisitorTasksMutationService,
    NewTaskFragment,
    TaskUpdatedFragment,
    VisitorTasksInput,
    VisitorTasksQuery, PermissionEnum,
} from '@app-graphql';
import { ContractService } from '@app-services/contract/contract.service';
import { clone } from 'ramda';
import { BehaviorSubject, firstValueFrom, Observable, Subscription } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { UserService } from '@app-services/user/user.service';

@Injectable({
    providedIn: 'root',
})
export class TasksService {

    public selectedTaskId = new BehaviorSubject<string | null>(null);

    private tasksQueue$: BehaviorSubject<NewTaskFragment[]> = new BehaviorSubject<NewTaskFragment[]>([]);
    private subscriptionToNewTask: Subscription;
    private subscriptionToUpdatedTask: Subscription;
    private isTasksInitialized = false;

    constructor(
        private readonly visitorTasksQueryService: VisitorTasksQueryService,
        private readonly ignoreVisitorTaskMutationService: IgnoreVisitorTaskMutationService,
        private readonly finishVisitorTasksMutationService: FinishVisitorTasksMutationService,
        private readonly visitorTaskUpdatedSubscriptionService: VisitorTaskUpdatedSubscriptionService,
        private readonly newVisitorTaskSubscriptionService: NewVisitorTaskSubscriptionService,
        private readonly contractsService: ContractService,
        private readonly userService: UserService,
    ) { }

    public initializeTasks(): void {
        this.isTasksInitialized = true;
        this.userService.hasPermission(PermissionEnum.VisitorTaskView).pipe(
            filter((hasRoles) => hasRoles),
            mergeMap(() => this.initializeTaskQueue()),
        ).subscribe(() => {
            this.initializeTaskSubscription();
        });
    }

    private async initializeTaskQueue(): Promise<void> {
        try {
            const resultVisitorTasks = await firstValueFrom(this.getVisitorTasks());
            if (resultVisitorTasks.data.visitorTasks) {
                const tasks = resultVisitorTasks?.data?.visitorTasks?.data || [];
                const tasksPending = tasks.filter((task) => task.status === VisitorTaskStatusEnum.Pending);
                this.tasksQueue$.next(tasksPending);
            }
        } catch (err) {
            console.error('Error getting visitor tasks');
            console.error(err);
        }

    }

    private initializeTaskSubscription(): void {
        if (this.subscriptionToNewTask) this.subscriptionToNewTask.unsubscribe();
        if (this.subscriptionToUpdatedTask) this.subscriptionToUpdatedTask.unsubscribe();

        this.subscriptionToNewTask = this.subscribeToNewTasks();
        this.subscriptionToUpdatedTask = this.subscribeToUpdatedTasks();
    }

    public getTasksQueue(): Observable<NewTaskFragment[]> {
        if (! this.isTasksInitialized) this.initializeTasks();
        return this.tasksQueue$.asObservable();
    }

    public getTasksQueueCount(): Observable<number> {
        if (! this.isTasksInitialized) this.initializeTasks();
        return this.tasksQueue$.pipe(map((tasksQueue) => tasksQueue.length));
    }

    public removeSelectedTask(): void {
        this.selectedTaskId.next(null);
    }

    public setSelectedTaskId(id: string) {
        this.selectedTaskId.next(id);
    }

    public getVisitorTaskById(id: string): Promise<ApolloQueryResult<VisitorTasksQuery>> {
        return firstValueFrom(this.visitorTasksQueryService.fetch(
            { input: { visitorId: id, isDone: false } }, { fetchPolicy: 'network-only' },
        ));
    }

    public async finishVisitorTasks(taskIds: string[]): Promise<void> {
        await firstValueFrom(this.finishVisitorTasksMutationService.mutate({ ids: taskIds }));
    }

    public ignoreTask(input: IgnoreVisitorTaskMutationVariables) {
        return firstValueFrom(this.ignoreVisitorTaskMutationService.mutate(input));
    }

    public getVisitorTasksByFilter(input: VisitorTasksInput) {
        return firstValueFrom(
            this.visitorTasksQueryService.fetch({ input }, { fetchPolicy: 'network-only' }),
        );
    }

    private getVisitorTasks() {
        return this.visitorTasksQueryService.fetch({ input: { isDone: false } }, { fetchPolicy: 'network-only' });
    }

    private subscribeToNewTasks(): Subscription {
        return this.newVisitorTaskSubscriptionService.subscribe().subscribe((res) => {
            if (res.data?.newVisitorTask) {
                this.checkTaskAndAddedToQueue(res.data?.newVisitorTask);
            }
        });
    }

    private subscribeToUpdatedTasks(): Subscription {
        return this.visitorTaskUpdatedSubscriptionService.subscribe().subscribe((res) => {
            if (res.data?.visitorTaskUpdated) {
                this.handleUpdatedTask(res.data?.visitorTaskUpdated);
            }
        });
    }

    private checkTaskAndAddedToQueue(task: NewTaskFragment): void {
        const currentTaskQueue = clone(this.tasksQueue$.value);
        const taskIndexOnQueue = currentTaskQueue.findIndex((currentTask) => task.id === currentTask.id);

        if (taskIndexOnQueue >= 0) currentTaskQueue[taskIndexOnQueue] = task;
        else currentTaskQueue.push(task);

        this.updateTaskQueue(currentTaskQueue);
    }

    private handleUpdatedTask(updatedTask: TaskUpdatedFragment): void {
        const currentTaskQueue = clone(this.tasksQueue$.value);
        const taskIndexOnQueue = currentTaskQueue.find((currentTask) => updatedTask.id === currentTask.id);

        if (! taskIndexOnQueue) return;

        taskIndexOnQueue.status = updatedTask.status;
        if (taskIndexOnQueue.status !== VisitorTaskStatusEnum.Pending && updatedTask.id === this.selectedTaskId.value) {
            this.removeSelectedTask();
        }
        taskIndexOnQueue.expiresAt = updatedTask.expiresAt;

        this.updateTaskQueue(currentTaskQueue);
    }

    private updateTaskQueue(newQueue: NewTaskFragment[]): void {
        const pendingTasks = newQueue.filter(
            (taskQueue) => taskQueue.status === VisitorTaskStatusEnum.Pending,
        );
        this.tasksQueue$.next(pendingTasks);
    }
}
