import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { all, equals, not, pipe } from 'ramda';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, map, share, shareReplay, startWith, switchMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class FormsService {
    public forms = new BehaviorSubject<{ form: FormGroup, name: string }[]>([]);
    public isValid$: Observable<boolean>;
    public isChanged$: Observable<boolean>;
    public formsChanged$: Observable<string[]>;
    public isSaving$ = new Subject<boolean>();

    constructor() {
        this.isValid$ = this.forms.pipe(
            map((forms) => forms.map((form) => form.form)),
            switchMap((forms) => combineLatest(
                forms.map((f) => f.statusChanges.pipe(
                    startWith(f.status),
                    map((status) => status === 'VALID'),
                )),
            )),
            map((formsAreValid) => all((v) => v, formsAreValid)),
            startWith(false),
            distinctUntilChanged(),
            shareReplay(1),
        );

        this.formsChanged$ = this.forms.pipe(
            map((forms) => forms.map(({ form, name }) => {
                const isChanged = pipe(
                    equals(form.value),
                    not,
                );
                return form.valueChanges.pipe(
                    map((formValue) => {
                        const formChanged = isChanged(formValue);
                        return formChanged ? name : null;
                    }),
                    startWith(null),
                );
            })),
            switchMap((observables) => combineLatest(observables)),
            map((formNames) => formNames.filter((formName): formName is string => formName !== null)),
            shareReplay(1),
        );

        this.isChanged$ = this.formsChanged$.pipe(
            map((formsChanged) => formsChanged.length > 0),
            share(),
        );
    }

    public addForm(form: FormGroup, name: string) {
        const forms = this.forms.getValue();
        this.forms.next([...forms, { form, name }]);
    }

    public getForms() {
        return this.forms.getValue().reduce((acc, val) => {
            acc[val.name] = val.form;
            return acc;
        }, {} as { [k: string]: FormGroup });

    }
}
