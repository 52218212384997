import { InMemoryCacheConfig, Reference } from '@apollo/client/core';
import { CheckInBasicFragment, CheckInQueueDocument, CheckInQueueQuery } from '@app-graphql';

export const cacheConfig: InMemoryCacheConfig = {
    typePolicies: {
        Query: {
            fields: {
                visitors: {
                    merge(existing: any, incoming: any) {
                        const visitorsRefs = incoming.data.map((visitor: any) => visitor.__ref);
                        const noRepeatedRefs = new Set(visitorsRefs);
                        const data = [...noRepeatedRefs].map((ref) => ({ __ref: ref }));
                        const merged = {
                            data,
                            paginatorInfo: incoming.paginatorInfo,
                        };
                        return merged;
                    },
                },
            },
        },
        Visitor: {
            merge: true,
        },
        CheckIn: {
            merge: true,
        },
        Subscription: {
            fields: {
                newCheckIn: {
                    merge: ((existing, incoming, options) => {
                        const { cache, variables, readField } = options;
                        const oVisitor = readField('visitor', incoming) as Reference;
                        const visitorIdentifications =
                            readField('visitorIdentifications', oVisitor) as Reference[];
                        const oIncoming = {
                            __typename: readField('__typename', incoming),
                            id: readField('id', incoming),
                            createdAt: readField('createdAt', incoming),
                            permission: readField('permission', incoming),
                            handledAt: readField('handledAt', incoming),
                            flags: readField('flags', incoming),
                            visitor: {
                                __typename: 'Visitor',
                                id: readField('id', oVisitor),
                                firstNames: readField('firstNames', oVisitor),
                                lastName: readField('lastName', oVisitor),
                                profile: readField('profile', oVisitor) || '',
                                checkInHistory: {
                                    __typename: 'CheckInPaginator',
                                    data: [],
                                },
                                visitorIdentifications: visitorIdentifications.map(id => {
                                    const oCruksCode = readField('cruksCode', id) as Reference;
                                    return {
                                        id: readField('id', id),
                                        cruksCodeForEstablishment: {
                                            id: readField('id', oCruksCode),
                                        },
                                    };
                                }),
                            },
                        } as CheckInBasicFragment;
                        const currentQueue = cache.readQuery({
                            query: CheckInQueueDocument,
                            variables,
                        }) as CheckInQueueQuery;

                        const checkInQueue = [...(currentQueue?.checkInQueue || [])];
                        const checkInIndex = checkInQueue.findIndex((checkin) => checkin.id === oIncoming.id);

                        if (checkInIndex >= 0) checkInQueue[checkInIndex] = oIncoming;
                        else checkInQueue.push(oIncoming);

                        const newCheckInsData: CheckInQueueQuery = { checkInQueue };



                        cache.writeQuery({
                            query: CheckInQueueDocument,
                            variables,
                            data: newCheckInsData,
                        });

                        return incoming;
                    }),
                },
            },
        },
    },
};
