import { Component, Input } from '@angular/core';
import { TailwindFontConfig, TailwindValues } from 'tailwindcss/tailwind-config';

@Component({
    selector: 'app-typography-example',
    templateUrl: './typography-example.component.html',
    styles: [
        ':host { display: contents; }',
    ],
})
export class TypographyExampleComponent {
    @Input()
    public fontGroup: TailwindFontConfig;

    @Input()
    public fontName: string;

    @Input()
    public fontWeights: TailwindValues;
}
