<div class="pb-2">
    <div>
        <h1 class="px-4 sticky top-0 shadow bg-white z-10">Colors</h1>
        <section class="mt-8 p-4"
                 id="colors"
        >
            <div *ngIf="config.theme.colors" class="px-8 grid grid-cols-4 gap-4 grid-flow-row">
                <div class="mb-8 contents"
                     *ngFor="let color of colors"
                >
                    <div class="col-span-4">
                        <h2>{{ color.key | titlecase }}</h2>
                    </div>
                    <app-color-card [colorGroup]="color.value"
                                    [colorName]="color.key"
                    ></app-color-card>
                </div>
            </div>
        </section>
    </div>
    <div>
        <h1 class="px-4 sticky top-0 shadow bg-white">Typography</h1>
        <section class="mt-8 px-8"
                 id="typography z-10"
        >
            <app-typography-example *ngFor="let font of fontSizes"
                                    [fontGroup]="font.value"
                                    [fontWeights]="fontWeights"
                                    [fontName]="font.key"
            ></app-typography-example>
        </section>
    </div>
    <div>
        <h1 class="px-4 sticky top-0 shadow bg-white z-10">Shadows</h1>
        <section class="mt-8 px-8"
                 id="shadows"
        >
            <div class="px-8 pb-8  grid grid-cols-4 gap-12 grid-flow-row">
                <app-shadow-card *ngFor="let shadow of boxShadows"
                                 [shadow]="shadow.value"
                                 [name]="shadow.key"
                ></app-shadow-card>
            </div>
        </section>
    </div>
    <div>
        <h1 class="px-4 sticky top-0 shadow bg-white z-10">Components</h1>
        <section class="mt-8"
                 id="components"
        >
            <div class="">
                <h2 class="px-8 sticky top-24 shadow bg-white z-10">Buttons</h2>

                <div class="mt-8 grid grid-cols-4 gap-4">
                    <div>
                        <div class="text-center mb-2">
                            <code [innerText]="'<button appButton buttonType=&quot;solid&quot; color=&quot;seafoam&quot;>Button</button>'"></code>
                        </div>
                        <div class="flex justify-center">
                            <button appButton
                                    buttonType="solid"
                                    color="seafoam"
                            >
                                Button
                            </button>
                        </div>
                    </div>
                    <div>
                        <div class="text-center mb-2">
                            <code [innerText]="'<button appButton buttonType=&quot;solid&quot; color=&quot;danger&quot;>Button</button>'"></code>
                        </div>
                        <div class="flex justify-center">
                            <button appButton
                                    buttonType="solid"
                                    color="danger"
                            >
                                Button
                            </button>
                        </div>
                    </div>
                    <div>
                        <div class="text-center mb-2">
                            <pre>btn-outline btn-danger</pre>
                        </div>
                        <div class="flex justify-center">
                            <button appButton
                                    buttonType="outline"
                                    color="danger"
                            >
                                Button
                            </button>
                        </div>
                    </div>
                    <div>
                        <div class="text-center mb-2">
                            <pre>btn-outline btn-seafoam</pre>
                        </div>
                        <div class="flex justify-center">
                            <button appButton
                                    buttonType="outline"
                                    color="seafoam"
                            >
                                Button
                            </button>
                        </div>
                        <div class="flex justify-center">
                        </div>
                    </div>
                    <div>
                        <div class="text-center mb-2">
                            <pre>btn-outline btn-gray</pre>
                        </div>
                        <div class="flex justify-center">
                            <button appButton
                                    buttonType="outline"
                                    color="gray"
                            >
                                Button
                            </button>
                        </div>
                    </div>
                </div>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
            </div>
        </section>
    </div>
</div>
