import { Injectable } from '@angular/core';
import { ContractFragment, ContractQueryService, PermissionEnum, RoleEnum } from '@app-graphql';
import { Nil, RequiredObject } from '@app-interfaces/generics';
import { TokensService } from '@app-services/tokens/tokens.service';
import { equals, isNil, mergeRight } from 'ramda';
import { firstValueFrom, merge, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, startWith, switchMap } from 'rxjs/operators';

export interface ContractInfo {
    isLoading: boolean
    contract: ContractFragment | Nil
}
export type NonNullableContract = RequiredObject<ContractInfo>;

@Injectable({
    providedIn: 'root',
})
export class ContractService {
    public currentContract$: Observable<ContractFragment>;
    public contract$: Observable<ContractInfo>;
    private contractRole$: Observable<RoleEnum>;
    private contractPermissions$: Observable<PermissionEnum[]>;

    constructor(
        private contractService: ContractQueryService,
        private tokensService: TokensService,
    ) {

        const defaultContractInfo: ContractInfo = {
            isLoading: true,
            contract: undefined,
        };

        const contractInfo$: Observable<Partial<ContractInfo>> = this.tokensService.currentContractId$.pipe(
            switchMap(async (contractId): Promise<Partial<ContractInfo>> => {
                if (isNil(contractId)) {
                    return {
                        isLoading: false,
                    };
                }

                const contract = await this.getContract(contractId);

                return {
                    isLoading: false,
                    contract: contract,
                };
            }),
            startWith(<ContractInfo>{
                isLoading: true,
                contract: undefined,
            }),
            shareReplay({ bufferSize: 1, refCount: true }),
        );

        const loadingPatches: Observable<Partial<ContractInfo>> = this.tokensService.currentContractId$.pipe(
            map((): Partial<ContractInfo> => ({ isLoading: true })),
        );

        this.contract$ = merge(contractInfo$, loadingPatches).pipe(
            map(shortcodeInfo => mergeRight(defaultContractInfo, shortcodeInfo)),
            distinctUntilChanged<ContractInfo>(equals),
        );

        this.currentContract$ = this.tokensService.currentContractId$.pipe(
            filter((id): id is string => typeof id === 'string' && id !== '' && id !== 'null'),
            switchMap((id) => this.getContract(id)),
            shareReplay(1),
        );

        this.contractRole$ = this.currentContract$.pipe(
            map(({ role }) => role.name),
            shareReplay(1),
        );

        this.contractPermissions$ = this.currentContract$.pipe(
            map(({ role }) => role.permissions.map(({ name }) => name)),
            map(permissions => [...new Set(permissions)]),
            shareReplay(1),
        );
    }

    public getContractRole(): Observable<RoleEnum> {
        return this.contractRole$;
    }

    public getContractPermissions(): Observable<PermissionEnum[]> {
        return this.contractPermissions$;
    }

    public async getContract(id: string): Promise<ContractFragment> {
        const contract = await firstValueFrom(this.contractService.fetch({ id }, { fetchPolicy: 'network-only' }));
        return contract.data.contract;
    }

    public contractHasRoles(roles: RoleEnum[]): Observable<boolean> {
        return this.contractRole$.pipe(map(role => roles.includes(role)));
    }
}
