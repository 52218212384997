import { Component, Input } from '@angular/core';
import { ScaleYFade } from '../../animations/scale-y-fade';

@Component({
    selector: 'app-loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.scss'],
    animations: [
        ScaleYFade,
    ],
})
export class LoadingComponent {
    @Input()
    public message: string = 'Laden...';

    @Input()
    public small: boolean = false;

    @Input()
    public hideMessageOnSmallVW: boolean = false;
}
