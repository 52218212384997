import { AfterViewInit, Component, ElementRef, OnInit } from '@angular/core';
// @ts-ignore
// eslint-disable-next-line import/extensions
import { MatSnackBar } from '@angular/material/snack-bar';
import { from, fromEvent } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
// eslint-disable-next-line import/no-extraneous-dependencies
import resolveConfig from 'tailwindcss/resolveConfig';
import { TailwindColorGroup, TailwindFontConfig, TailwindValues } from 'tailwindcss/tailwind-config';
// @ts-ignore
// eslint-disable-next-line import/extensions
import config from '../../../../../tailwind.config.js';

interface Color {
    key: string;
    value: TailwindColorGroup | string
}

interface FontSize {
    key: string;
    value: TailwindFontConfig
}

interface BoxShadow {
    key: string;
    value: string;
}

@Component({
    selector: 'app-style-guide',
    templateUrl: './style-guide.component.html',
    styleUrls: ['./style-guide.component.scss'],
})
export class StyleGuideComponent implements OnInit, AfterViewInit {
    public config = resolveConfig(config);
    public colors: Color[] = [];
    public fontSizes: FontSize[];
    public fontWeights: TailwindValues;
    public boxShadows: BoxShadow[] = [];

    constructor(
        private el: ElementRef,
        private snackBar: MatSnackBar,
    ) { }

    public ngOnInit(): void {
        if (typeof this.config.theme.colors === 'object') {
            this.colors = Object.entries(this.config.theme.colors)
                .map(([key, value]) => ({ key, value }))
                .filter((color): color is Color => typeof color.value !== 'function');
        }

        if (typeof this.config.theme.fontSize === 'object') {
            this.fontSizes = Object.entries(this.config.theme.fontSize)
                .map(([key, value]) => ({ key, value }));
        }
        if (typeof this.config.theme.fontWeight === 'object') {
            this.fontWeights = this.config.theme.fontWeight;
        }
        if (typeof this.config.theme.boxShadow === 'object') {
            this.boxShadows = Object.entries(this.config.theme.boxShadow).map(([key, value]) => ({ key, value }));
        }



    }

    public ngAfterViewInit(): void {
        fromEvent((this.el.nativeElement as HTMLElement).querySelectorAll('pre'), 'click').pipe(
            map((event) => (event.target as HTMLPreElement).innerText),
            switchMap((val) => from(window.navigator.clipboard.writeText(val))),
        ).subscribe(() => {
            this.snackBar.open('Copied to clipboard!', undefined, {
                duration: 1500,
            });
        });
    }

}
