import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { AlertComponent } from '@app-components/alert/alert.component';
import { CameraDialogComponent } from '@app-components/camera-dialog/camera-dialog.component';
import { ConfirmDialogComponent } from '@app-components/confirm-dialog/confirm-dialog.component';
import { ContentLayoutComponent } from '@app-components/content-layout/content-layout.component';
import { AskForBsnComponent } from '@app-components/dialogs/ask-for-bsn/ask-for-bsn.component';
import {
    ViewExternalSignalNoteDialogComponent,
} from '@app-components/dialogs/view-external-signal-note-dialog/view-external-signal-note-dialog.component';
import {
    ViewInternalSignalNoteDialogComponent,
} from '@app-components/dialogs/view-internal-signal-note-dialog/view-internal-signal-note-dialog.component';
import {
    VisitorLogBlacklistComponent,
} from '@app-components/dialogs/visitor-log-blacklist/visitor-log-blacklist.component';
import {
    VisitorLogOverrideComponent,
} from '@app-components/dialogs/visitor-log-override/visitor-log-override.component';
import {
    VisitorReportEntryLogComponent,
} from '@app-components/dialogs/visitor-report-entry-log/visitor-report-entry-log.component';
import {
    ExternalSignalNoteViewComponent,
} from '@app-components/external-signal-note-view/external-signal-note-view.component';
import { FiltersComponent } from '@app-components/filters/filters.component';
import { HeaderPageComponent } from '@app-components/header-page/header-page.component';
import { HeaderComponent } from '@app-components/header/header.component';
import {
    InternalSignalNoteViewComponent,
} from '@app-components/internal-signal-note-view/internal-signal-note-view.component';
import { LeftColumnHeaderComponent } from '@app-components/left-column-header/left-column-header.component';
import { ListBubbleComponent } from '@app-components/list-bubble/list-bubble.component';
import { LoadingComponent } from '@app-components/loading/loading.component';
import { SorterComponent } from '@app-components/sorter/sorter.component';
import { StatusCardComponent } from '@app-components/status-card/status-card.component';
import { TabHistoryComponent } from '@app-components/tab-history/tab-history.component';
import { TabNotesComponent } from '@app-components/tab-notes/tab-notes.component';
import { TaskCardComponent } from '@app-components/task-card/task-card.component';
import { UsersDetailComponent } from '@app-components/users-detail/users-detail.component';
import { ValidationErrorsComponent } from '@app-components/validation-errors/validation-errors.component';
import {
    ViewInterventionNoteOneComponent,
} from '@app-components/view-intervention-note-one/view-intervention-note-one.component';
import {
    ViewInterventionNoteTwoComponent,
} from '@app-components/view-intervention-note-two/view-intervention-note-two.component';
import {
    ViewInterviewCareNoteComponent,
} from '@app-components/view-interview-care-note/view-interview-care-note.component';
import {
    ViewInterviewSecurityNoteComponent,
} from '@app-components/view-interview-security-note/view-interview-security-note.component';
import {
    ViewInterviewServiceInterviewComponent,
} from '@app-components/view-interview-service-interview/view-interview-service-interview.component';
import { PulseAnimationDirective } from '@app-directives/animations/pulse-animation/pulse-animation.directive';
import { ImgFallbackDirective } from '@app-directives/img-fallback/img-fallback.directive';
import { PermissionRestrictDirective } from '@app-directives/permission-restrict/permission-restrict.directive';
import { RoleRestrictDirective } from '@app-directives/role-restrict/role-restrict.directive';
import { ButtonDirective } from '@app-directives/tailwind/button/button.directive';
import { ClearButtonDirective } from '@app-directives/tailwind/button/clear-button/clear-button.directive';
import { OutlineButtonDirective } from '@app-directives/tailwind/button/outline-button/outline-button.directive';
import { SolidButtonDirective } from '@app-directives/tailwind/button/solid-button/solid-button.directive';
import { InputDirective } from '@app-directives/tailwind/input/input.directive';
import { TailwindDirective } from '@app-directives/tailwind/tailwind.directive';
import { ArrayHasItemPipe } from '@app-pipes/array-has-item/array-has-item.pipe';
import { AsBlacklistTypePipe } from '@app-pipes/as-blacklist-type/as-blacklist-type.pipe';
import { AsCareNotePipe } from '@app-pipes/as-interview-type/as-care-note/as-care-note.pipe';
import {
    AsInterventionNoteOnePipe,
} from '@app-pipes/as-interview-type/as-intervention-note-one/as-intervention-note-one.pipe';
import { AsSecurityNotePipe } from '@app-pipes/as-interview-type/as-security-note/as-security-note.pipe';
import { AsServiceInterviewPipe } from '@app-pipes/as-interview-type/as-service-interview/as-service-interview.pipe';
import { AsTaskTypePipe } from '@app-pipes/as-task-type/as-task-type.pipe';
import { CommunicationClassColorPipe } from '@app-pipes/communication-class-color/communication-class-color.pipe';
import { ContractHasRolesPipe } from '@app-pipes/contract-has-roles/contract-has-roles.pipe';
import { CountryCodePipe } from '@app-pipes/country-code/country-code.pipe';
import { FilterDeletedIdsPipe } from '@app-pipes/filter-deleted-ids/filter-deleted-ids.pipe';
import {
    InterventionMeasureTargetEnumTranslatorPipe,
} from '@app-pipes/intervention-measure-target-enum-translator/intervention-measure-target-enum-translator.pipe';
import {
    InterventionMeasuresEnumTranslatorPipe,
} from '@app-pipes/intervention-measures-enum-translator/intervention-measures-enum-translator.pipe';
import {
    InterventionNoteOneCauseEnumTranslatorPipe,
} from '@app-pipes/intervention-note-one-cause-enum-translator/intervention-note-one-cause-enum-translator.pipe';
import { InterviewCountsPipe } from '@app-pipes/interview-counts/interview-counts.pipe';
import { IsDeletablePipe } from '@app-pipes/is-deletable/is-deletable.pipe';
import {
    OptionalBooleanEnumTranslatorPipe,
} from '@app-pipes/optional-boolean-enum-translator/optional-boolean-enum-translator.pipe';
import {
    ServiceInterviewCauseEnumTranslatorPipe,
} from '@app-pipes/service-interview-cause-enum-translator/service-interview-cause-enum-translator.pipe';
import { StaffMemberToUserBubblePipe } from '@app-pipes/staff-member-to-user-bubble/staff-member-to-user-bubble.pipe';
import { StringToDatePipe } from '@app-pipes/string-to-date/string-to-date.pipe';
import { TimeAgoPipe } from '@app-pipes/time-ago/time-ago.pipe';
import { TimeLeftPipe } from '@app-pipes/time-left/time-left.pipe';
import { UserRoleTranslationPipe } from '@app-pipes/user-role-translation/user-role-translation.pipe';
import { VisitorFullNamePipe } from '@app-pipes/visitor-full-name/visitor-full-name.pipe';
import { VisitorLogRelatablePipe } from '@app-pipes/visitor-log-relatable/visitor-log-relatable.pipe';
import {
    VisitorNotesEnumTranslatorPipe,
} from '@app-pipes/visitor-notes-enum-translator/visitor-notes-enum-translator.pipe';
import {
    VisitorNoticeTaskTypeTranslationPipe,
} from '@app-pipes/visitor-notice-task-type-translation/visitor-notice-task-type-translation.pipe';
import {
    VisitorOrderEnumTranslationPipe,
} from '@app-pipes/visitor-order-enum-translation/visitor-order-enum-translation.pipe';
import {
    VisitorReactionToMeasuresEnumTranslatorPipe,
} from '@app-pipes/visitor-reaction-to-measures-enum-translator/visitor-reaction-to-measures-enum-translator.pipe';
import { VisitorToUserBubblePipe } from '@app-pipes/visitor-to-user-bubble/visitor-to-user-bubble.pipe';
import { LayoutAuthComponent } from '../auth/components/layout-auth/layout-auth.component';
import { GuestIdentificationComponent } from '../guests/components/guest-identification/guest-identification.component';
import { ColorCardComponent } from './components/color-card/color-card.component';
import { ShadowCardComponent } from './components/shadow-card/shadow-card.component';
import { TypographyExampleComponent } from './components/typography-example/typography-example.component';
import { StyleGuideComponent } from './style-guide/style-guide.component';
import { TranslateModule } from '@ngx-translate/core';
import { UiComponentsModule } from '@app-components/ui/ui.module';
import { IconStatusesComponent } from '../guests/components/icon-statuses/icon-statuses.component';
import { MockButtonDirective } from '../../../../tests/mock-classes/button.mock.directive';
import { SortNoteTypeEnumsPipe } from '@app-pipes/sort-note-type-enums.pipe/sort-note-type-enums.pipe';
import {
    StripIdFromJoiningProfilesLogPipe,
} from '@app-pipes/strip-id-from-joining-profiles-log/strip-id-from-joining-profiles-log.pipe';
import { VisitorHasValidBsnPipe } from '@app-pipes/visitor-has-valid-bsn/visitor-has-valid-bsn.pipe';

@NgModule({
    declarations: [
        ConfirmDialogComponent,
        ValidationErrorsComponent,
        UsersDetailComponent,
        StatusCardComponent,
        TabNotesComponent,
        TabHistoryComponent,
        TimeAgoPipe,
        VisitorFullNamePipe,
        ImgFallbackDirective,
        HeaderComponent,
        StyleGuideComponent,
        ColorCardComponent,
        TypographyExampleComponent,
        ShadowCardComponent,
        SolidButtonDirective,
        ButtonDirective,
        TailwindDirective,
        OutlineButtonDirective,
        ListBubbleComponent,
        ContentLayoutComponent,
        ClearButtonDirective,
        LoadingComponent,
        CountryCodePipe,
        InputDirective,
        AlertComponent,
        LayoutAuthComponent,
        GuestIdentificationComponent,
        RoleRestrictDirective,
        CameraDialogComponent,
        PermissionRestrictDirective,
        ViewInterviewSecurityNoteComponent,
        InterviewCountsPipe,
        AsSecurityNotePipe,
        ViewInterviewServiceInterviewComponent,
        ViewInterviewCareNoteComponent,
        ViewInterventionNoteOneComponent,
        ViewInterventionNoteTwoComponent,
        AsServiceInterviewPipe,
        AsCareNotePipe,
        AsInterventionNoteOnePipe,
        VisitorLogRelatablePipe,
        VisitorLogBlacklistComponent,
        VisitorLogOverrideComponent,
        FilterDeletedIdsPipe,
        VisitorNoticeTaskTypeTranslationPipe,
        AsTaskTypePipe,
        AsBlacklistTypePipe,
        UserRoleTranslationPipe,
        SorterComponent,
        VisitorOrderEnumTranslationPipe,
        HeaderPageComponent,
        LeftColumnHeaderComponent,
        StaffMemberToUserBubblePipe,
        VisitorToUserBubblePipe,
        PulseAnimationDirective,
        VisitorReportEntryLogComponent,
        TaskCardComponent,
        TimeLeftPipe,
        StringToDatePipe,
        AskForBsnComponent,
        ArrayHasItemPipe,
        VisitorNotesEnumTranslatorPipe,
        InterventionMeasuresEnumTranslatorPipe,
        VisitorReactionToMeasuresEnumTranslatorPipe,
        InterventionNoteOneCauseEnumTranslatorPipe,
        OptionalBooleanEnumTranslatorPipe,
        ServiceInterviewCauseEnumTranslatorPipe,
        InternalSignalNoteViewComponent,
        ExternalSignalNoteViewComponent,
        FiltersComponent,
        ViewExternalSignalNoteDialogComponent,
        ViewInternalSignalNoteDialogComponent,
        InterventionMeasureTargetEnumTranslatorPipe,
        ContractHasRolesPipe,
        CommunicationClassColorPipe,
        IsDeletablePipe,
        IconStatusesComponent,
        SortNoteTypeEnumsPipe,
        StripIdFromJoiningProfilesLogPipe,
        MockButtonDirective,
        VisitorHasValidBsnPipe,
    ],
    exports: [
        ConfirmDialogComponent,
        ValidationErrorsComponent,
        UsersDetailComponent,
        StatusCardComponent,
        TabNotesComponent,
        TabHistoryComponent,
        TimeAgoPipe,
        VisitorFullNamePipe,
        ButtonDirective,
        TailwindDirective,
        SolidButtonDirective,
        OutlineButtonDirective,
        ContentLayoutComponent,
        ListBubbleComponent,
        HeaderComponent,
        ClearButtonDirective,
        LoadingComponent,
        InputDirective,
        AlertComponent,
        LayoutAuthComponent,
        GuestIdentificationComponent,
        RoleRestrictDirective,
        CountryCodePipe,
        CameraDialogComponent,
        PermissionRestrictDirective,
        InterviewCountsPipe,
        VisitorLogBlacklistComponent,
        VisitorLogOverrideComponent,
        VisitorNoticeTaskTypeTranslationPipe,
        AsTaskTypePipe,
        AsBlacklistTypePipe,
        UserRoleTranslationPipe,
        SorterComponent,
        VisitorOrderEnumTranslationPipe,
        HeaderPageComponent,
        LeftColumnHeaderComponent,
        StaffMemberToUserBubblePipe,
        VisitorToUserBubblePipe,
        PulseAnimationDirective,
        VisitorReportEntryLogComponent,
        TaskCardComponent,
        TimeLeftPipe,
        StringToDatePipe,
        AskForBsnComponent,
        ArrayHasItemPipe,
        VisitorNotesEnumTranslatorPipe,
        InterventionMeasuresEnumTranslatorPipe,
        VisitorReactionToMeasuresEnumTranslatorPipe,
        InterventionNoteOneCauseEnumTranslatorPipe,
        OptionalBooleanEnumTranslatorPipe,
        ServiceInterviewCauseEnumTranslatorPipe,
        InternalSignalNoteViewComponent,
        ExternalSignalNoteViewComponent,
        FiltersComponent,
        ViewExternalSignalNoteDialogComponent,
        ViewInternalSignalNoteDialogComponent,
        InterventionMeasureTargetEnumTranslatorPipe,
        ImgFallbackDirective,
        ContractHasRolesPipe,
        CommunicationClassColorPipe,
        IsDeletablePipe,
        IconStatusesComponent,
        MockButtonDirective,
        SortNoteTypeEnumsPipe,
        StripIdFromJoiningProfilesLogPipe,
        VisitorHasValidBsnPipe,
    ],
    imports: [
        CommonModule,
        MatMenuModule,
        MatIconModule,
        MatDividerModule,
        MatRadioModule,
        MatDatepickerModule,
        MatCardModule,
        MatListModule,
        MatTabsModule,
        MatButtonModule,
        CommonModule,
        RouterModule,
        MatInputModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        MatTableModule,
        MatSelectModule,
        MatPaginatorModule,
        MatDialogModule,
        MatBadgeModule,
        MatSnackBarModule,
        MatRippleModule,
        MatTooltipModule,
        MatSidenavModule,
        MatTabsModule,
        TranslateModule,
        UiComponentsModule,
    ],
})
export class SharedModule {
}
