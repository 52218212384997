import { Directive, OnDestroy, OnInit } from '@angular/core';
import { AnimationsService } from '@app-services/animations/animations.service';

@Directive({
    selector: '[appAnimations]',
})
export abstract class AnimationsDirective implements OnInit, OnDestroy {

    public animation: Animation;
    private animationId: string;

    constructor(private animationsService: AnimationsService) { }

    public ngOnInit(): void {
        this.animationId = this.animationsService.addAnimationAndRefreshAnimations(this.animation);
    }

    public ngOnDestroy(): void {
        this.animationsService.deleteAnimation(this.animationId);
    }

}
