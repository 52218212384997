import { NgModule } from '@angular/core';
import { IconComponent } from '@app-components/ui/icon/icon.component';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
    declarations: [
        IconComponent,
    ],
    exports: [
        IconComponent,
    ],
    imports: [
        MatIconModule,
    ],
})
export class UiComponentsModule {
}
