import { Directive, Input } from '@angular/core';

@Directive({
    selector: '[appButton]',
})
export class MockButtonDirective {
    @Input() public color: any;
    @Input() public disabled: any;
    @Input() public buttonType: any;
    @Input() public extraClasses: any;
}
