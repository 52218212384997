import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TokensService } from '@app-services/tokens/tokens.service';

@Injectable({
    providedIn: 'root',
})
export class ContractGuard  {
    constructor(
        private tokensService: TokensService,
        private router: Router,
    ) {
    }

    public canActivate(): Promise<boolean> | boolean {
        return this.tokensService.currentContractId$.getValue()
            ? true
            : this.router.navigateByUrl('/select-contract');
    }

}
