import { Pipe, PipeTransform } from '@angular/core';
import { differenceInSeconds, formatDistanceToNowStrict } from 'date-fns';
import { BehaviorSubject, Observable, of, timer } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import NL from 'date-fns/locale/nl';

@Pipe({
    name: 'timeLeft',
})
export class TimeLeftPipe implements PipeTransform {

    public transform(dateTo: Date, descriptive = true): Observable<string> {
        if (! dateTo) return of('');

        try {
            const timerUpdater = new BehaviorSubject<number>(0);
            return timerUpdater.pipe(
                switchMap((updateOn) => timer(updateOn)),
                map(() => {
                    const timeLeft = formatDistanceToNowStrict(dateTo, { locale: NL });
                    return descriptive ? `Nog ${timeLeft} actief` : timeLeft;
                }),
                tap(() => {
                    timerUpdater.next(this.distanceToDelay(differenceInSeconds(dateTo, new Date())));
                }),
            );
        } catch (ex) {
            return of('');
        }

    }

    public distanceToDelay(distanceInSeconds: number) {
        if (distanceInSeconds < 60) {
            return 1000;
        }
        if (distanceInSeconds < (60 * 60)) {
            return 60 * 1000;
        }
        if (distanceInSeconds < (60 * 60 * 24)) {
            return (60 * 60) * 1000;
        }
        return (60 * 60 * 24) * 1000;
    }
}
