import { Injectable } from '@angular/core';
import { LoginResponseFragment, Maybe } from '@app-graphql';
import { BehaviorSubject } from 'rxjs';

const ACCESS_TOKEN = 'accessToken';
const CONTRACT_ID = 'contractId';
const ENTRANCE_ID = 'entranceId';

@Injectable({
    providedIn: 'root',
})
export class TokensService {
    public auth: BehaviorSubject<LoginResponseFragment | null>;
    public currentContractId$: BehaviorSubject<Maybe<string>>;
    public currentEntranceId$: BehaviorSubject<Maybe<string>>;

    constructor() {
        const initialPayload: LoginResponseFragment = {
            token: localStorage.getItem(ACCESS_TOKEN) ?? '',
            contracts: [],
        };
        const previousContract = localStorage.getItem(CONTRACT_ID);
        const previousEntrance = localStorage.getItem(ENTRANCE_ID);
        this.auth = new BehaviorSubject<LoginResponseFragment | null>(
            initialPayload.token.length ? initialPayload : null,
        );
        this.currentContractId$ = new BehaviorSubject<Maybe<string>>(
            previousContract,
        );
        this.currentEntranceId$ = new BehaviorSubject<Maybe<string>>(
            previousEntrance,
        );
    }

    public setTokens(payload: LoginResponseFragment | null) {
        if (! payload) {
            localStorage.setItem(ACCESS_TOKEN, '');
            localStorage.setItem(CONTRACT_ID, '');
            localStorage.setItem(ENTRANCE_ID, '');
            this.currentContractId$.next(null);
            this.auth.next(null);
            this.currentEntranceId$.next(null);
        } else {
            const localPayload: LoginResponseFragment = {
                ...payload,
            };
            localStorage.setItem(ACCESS_TOKEN, payload.token!);
            this.auth.next(localPayload);
        }
    }

    public setCurrentContract(contractId: Maybe<string>) {
        localStorage.setItem(ENTRANCE_ID, '');
        this.currentEntranceId$.next(null);
        localStorage.setItem(CONTRACT_ID, contractId || '');
        this.currentContractId$.next(contractId);
    }

    public setCurrentEntrance(entranceId: string) {
        localStorage.setItem(ENTRANCE_ID, entranceId);
        this.currentEntranceId$.next(entranceId);
    }
}
