<header
    class="border-b-2 border-gray-border sticky top-0 z-50 bg-white h-32 p-4 flex flex-col items-center justify-center pt-8"
>
    <div class="w-full">
        <div class="flex gap-x-8 mb-4 justify-between items-center">
            <div class="flex justify-center items-center">
                <img class="h-16 w-16" src="assets/img/ace.png" />
            </div>
            <div class="flex justify-end items-center">
                <!--            </div>-->
                <!--            <div class="col-span-5">-->
                <ng-container *ngIf="contracts">
                    <div class="px-2" *ngIf="contracts.length > 0">
                        <div>
                            <mat-form-field>
                                <mat-label>Selecteer contract</mat-label>
                                <mat-select [formControl]="contractForm">
                                    <mat-option
                                        [value]="contract.id"
                                        *ngFor="let contract of contracts"
                                    >
                                        {{ contract.readableName }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </ng-container>
                <div class="px-2" *ngIf="me$ && (me$ | async) as me">
                    <button
                        appButton
                        buttonType="outline"
                        color="seafoam"
                        routerLinkActive="bg-input-background"
                        routerLink="/profile/user"
                        class="group hover:bg-seafoam focus:outline-none focus:ring-2 focus:ring-opacity-50"
                    >
                        <div class="flex flex-col items-center">
                            <div class="group-hover:text-white">
                                {{ me.firstName }} {{ me.lastName }}
                            </div>
                        </div>
                    </button>
                </div>
            </div>
        </div>
        <nav mat-tab-nav-bar
            [tabPanel]="tabPanel"
        >
            <a
                #tabLink
                mat-tab-link
                routerLink="{{ menuItem.route }}"
                [attr.data-nav]="menuItem.name"
                routerLinkActive="active-link"
                [active]="tabLink.elementRef.nativeElement.classList.contains('active-link')"
                *ngFor="let menuItem of menuItems"
            >
                <span class="whitespace-nowrap">{{ menuItem.name }}</span>
                <div
                    appPulseAnimation
                    class="badge ml-3 transform relative border-seafoam bg-seafoam text-white rounded-full flex items-center justify-center h-8 w-8"
                    *ngIf="menuItem.badge | async as badge"
                >
                    <span>{{ badge }}</span>
                </div>
            </a>
        </nav>
        <mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>
    </div>
</header>
