// eslint-disable-next-line import/no-extraneous-dependencies
const Color = require('color');

/* eslint-disable global-require,import/no-extraneous-dependencies */
module.exports = {
    purge: ['./src/**/*.{html,ts}', './.purgeignore'],
    darkMode: false, // or 'media' or 'class'
    theme: {
        colors: {
            current: {
                DEFAULT: 'currentColor',
            },
            transparent: {
                DEFAULT: 'transparent',
            },
            text: {
                lighter: '#696773',
                DEFAULT: '#211b3f',
                danger: '#FF0000',
            },
            seafoam: {
                DEFAULT: '#5E878D',
                darker: Color('#5E878D').darken(0.1).hex(),
                disabled: '#9e9da4',
            },
            danger: {
                lighter: '#ffe7e0',
                DEFAULT: '#e23a16',
                darker: Color('#e23a16').darken(0.1).hex(),
            },
            success: {
                lighter: '#e1f5db',
                DEFAULT: '#38c114',
                darker: Color('#38c114').darken(0.1).hex(),
            },
            info: {
                DEFAULT: '#9e9da4',
            },
            warn: {
                DEFAULT: '#e2bc16',
            },
            gray: {
                lighter: '#e0e4eb',
                DEFAULT: '#cad3df',
                darker: Color('#cad3df').darken(0.1).hex(),
                border: '#e3e6ea',
            },
            input: {
                border: '#E2E2E4',
                background: '#F6F7F9',
            },
            white: {
                DEFAULT: '#fff',
            },
        },
        fontFamily: {
            sans: ['Roboto', 'ui-sans-serif', 'system-ui'],
        },
        fontSize: {
            h1: ['4rem'],
            h2: ['2rem'],
            h3: ['1.5rem'],
            lg: ['1.125rem'],
            p: ['1rem'],
            small: ['0.75rem'],
            base: ['1rem'],
        },
        fontWeight: {
            normal: 400,
            bold: 500,
        },
        boxShadow: {
            small: '0 4px 14px 0 rgba(0, 0, 0, .2)',
            DEFAULT: '0 8px 28px 3px rgba(0, 0, 0, .2)',
            full: '0 8px 28px 28px rgba(0, 0, 0, .2)',
            large: '0 0 80px 0 rgba(0, 0, 0, .2)',
            success: '0 8px 28px 0 rgba(37, 114, 17, .24)',
            warn: '0 8px 28px 0 rgba(226, 188, 22, .24)',
            error: '0 8px 28px 0 rgba(226, 58, 22, .24)',
            'success-small': '0 4px 14px 0 rgba(37, 114, 17, .2)',
            'warn-small': '0 4px 14px 0 rgba(226, 188, 22, .2)',
            'error-small': '0 4px 14px 0 rgba(226, 58, 22, .2)',
            'info-small': '0 4px 14px 0 rgba(158, 157, 164, .2)',
            unselected: '0 8px 28px 3px rgba(33, 27, 63, .1)',
        },
        extend: {
            inset: {
                46: '10.5rem',
                47: '11.6rem',
            },
            spacing: {
                'screen-header': 'calc(100vh - 8rem)',
                'screen-xl': '1280px',
                18: '4.5rem',
                22: '5.5rem',
                30: '7.5rem',
                76: '19rem',
            },
            saturate: {
                25: '.25',
            },
            zIndex: {
                '-1': '-1',
                100: '100',
            },
            lineHeight: {
                'extra-loose': '2.5',
            },
            maxHeight: {
                '1/4': '25vh',
                '1/2': '50vh',
                '3/4': '75vh',
            },
            maxWidth: {
                '1/2': '50vw',
            },
            minWidth: {
                '1/2': '50vw',
            },
            gridTemplateRows: {
                8: 'repeat(8, auto)',
                10: 'repeat(10, auto)',
            },
            gridTemplateColumns: {
                bubble: '72px auto 40px',
            },
            animation: {
                loaderA: 'loaderA 2s linear infinite',
                loaderB: 'loaderB 2s linear infinite',
                loaderC: 'loaderC 2s linear infinite',
                loaderD: 'loaderD 2s linear infinite',
            },
            keyframes: {
                loaderA: {
                    'from, 4%': {
                        strokeDasharray: '0, 660',
                        strokeWidth: 20,
                        strokeDashoffset: -330,
                    },
                    '12%': {
                        strokeDasharray: '60, 600',
                        strokeWidth: 30,
                        strokeDashoffset: -335,
                    },
                    '32%': {
                        strokeDasharray: '60, 600',
                        strokeWidth: 30,
                        strokeDashoffset: -595,
                    },
                    '40%, 54%': {
                        strokeDasharray: '0, 660',
                        strokeWidth: 20,
                        strokeDashoffset: -660,
                    },
                    '62%': {
                        strokeDasharray: '60, 600',
                        strokeWidth: 30,
                        strokeDashoffset: -665,
                    },
                    '82%': {
                        strokeDasharray: '60, 600',
                        strokeWidth: 30,
                        strokeDashoffset: -925,
                    },
                    '90%, to': {
                        strokeDasharray: '0, 660',
                        strokeWidth: 20,
                        strokeDashoffset: -990,
                    },
                },
                loaderB: {
                    'from, 12%': {
                        strokeDasharray: '0, 220',
                        strokeWidth: 20,
                        strokeDashoffset: -110,
                    },
                    '20%': {
                        strokeDasharray: '20, 200',
                        strokeWidth: 30,
                        strokeDashoffset: -115,
                    },
                    '40%': {
                        strokeDasharray: '20, 200',
                        strokeWidth: 30,
                        strokeDashoffset: -195,
                    },
                    '48%, 62%': {
                        strokeDasharray: '0, 220',
                        strokeWidth: 20,
                        strokeDashoffset: -220,
                    },
                    '70%': {
                        strokeDasharray: '20, 200',
                        strokeWidth: 30,
                        strokeDashoffset: -225,
                    },
                    '90%': {
                        strokeDasharray: '20, 200',
                        strokeWidth: 30,
                        strokeDashoffset: -305,
                    },
                    '98%, to': {
                        strokeDasharray: '0, 220',
                        strokeWidth: 20,
                        strokeDashoffset: -330,
                    },
                },
                loaderC: {
                    from: {
                        strokeDasharray: '0, 440',
                        strokeWidth: 20,
                        strokeDashoffset: 0,
                    },
                    '8%': {
                        strokeDasharray: '40, 400',
                        strokeWidth: 30,
                        strokeDashoffset: -5,
                    },
                    '28%': {
                        strokeDasharray: '40, 400',
                        strokeWidth: 30,
                        strokeDashoffset: -175,
                    },
                    '36%, 58%': {
                        strokeDasharray: '0, 440',
                        strokeWidth: 20,
                        strokeDashoffset: -220,
                    },
                    '66%': {
                        strokeDasharray: '40, 400',
                        strokeWidth: 30,
                        strokeDashoffset: -225,
                    },
                    '86%': {
                        strokeDasharray: '40, 400',
                        strokeWidth: 30,
                        strokeDashoffset: -395,
                    },
                    '94%, to': {
                        strokeDasharray: '0, 440',
                        strokeWidth: 20,
                        strokeDashoffset: -440,
                    },
                },
                loaderD: {
                    'from, 8%': {
                        strokeDasharray: '0, 440',
                        strokeWidth: 20,
                        strokeDashoffset: 0,
                    },
                    '16%': {
                        strokeDasharray: '40, 400',
                        strokeWidth: 30,
                        strokeDashoffset: -5,
                    },
                    '36%': {
                        strokeDasharray: '40, 400',
                        strokeWidth: 30,
                        strokeDashoffset: -175,
                    },
                    '44%, 50%': {
                        strokeDasharray: '0, 440',
                        strokeWidth: 20,
                        strokeDashoffset: -220,
                    },
                    '58%': {
                        strokeDasharray: '40, 400',
                        strokeWidth: 30,
                        strokeDashoffset: -225,
                    },
                    '78%': {
                        strokeDasharray: '40, 400',
                        strokeWidth: 30,
                        strokeDashoffset: -395,
                    },
                    '86%, to': {
                        strokeDasharray: '0, 440',
                        strokeWidth: 20,
                        strokeDashoffset: -440,
                    },
                },
            },
        },
    },
    variants: {
        extend: {
            webkitTextFill: ['autofill'],
            webkitBoxShadow: ['autofill'],
            fontSize: ['autofill-first-line'],
        },
    },
    plugins: [
        require('@tailwindcss/aspect-ratio'),
        require('@tailwindcss/forms'),
        require('@tailwindcss/line-clamp'),
        require('@tailwindcss/typography'),
        require('./plugins/autofill'),
        require('./plugins/webkit-box-shadow'),
        require('./plugins/webkit-text-fill-color'),
        require('./plugins/first-line'),
    ],
};
