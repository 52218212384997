import { Injectable } from '@angular/core';
import { ApplicationUpdatedSubscriptionService } from '@app-graphql';
import { Observable } from 'rxjs';
import { filter, map, shareReplay } from 'rxjs/operators';

export const reloadTimeInMilliseconds = 5000;

@Injectable({
    providedIn: 'root',
})
export class ReloaderAppService {

    private appNeedsToReload$: Observable<void>;

    constructor(private readonly applicationUpdatedSubscriptionService: ApplicationUpdatedSubscriptionService) {
        this.setAppNeedsToReload$();
    }

    public getAppNeedsToReload$(): Observable<void> {
        return this.appNeedsToReload$;
    }

    private setAppNeedsToReload$() {
        this.appNeedsToReload$ = this.applicationUpdatedSubscriptionService.subscribe().pipe(
            filter((res) => !! res.data?.applicationUpdated?.forceRefresh),
            map(() => undefined),
            shareReplay(1),
        );
    }
}
