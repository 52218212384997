<div class="contents">
    <div class="pt-28 rounded flex flex-col"
         [style]="{ 'box-shadow': shadow }"
    >
        <div class="bg-white p-2 rounded-b">
            <div class="font-bold mb-4">
                {{ name | titlecase }}
            </div>
            <div>
                <pre class="w-full overflow-hidden overflow-ellipsis whitespace-nowrap">{{ 'shadow' + (name === 'DEFAULT' ? '' : '-' + name) }}</pre>
            </div>
        </div>
    </div>
</div>
