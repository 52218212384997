const plugin = require('tailwindcss/plugin');

module.exports = plugin(({ addVariant, e }) => {
    addVariant('autofill-first-line', ({ modifySelectors, separator }) => {
        modifySelectors(({ className }) => {
            const newClass = e(`autofill-first-line${separator}${className}`);
            return [
                `.${newClass}:-webkit-autofill::first-line`,
                `.${newClass}:-webkit-autofill:hover::first-line`,
                `.${newClass}:-webkit-autofill:focus::first-line`,
            ].join(',');
        });
    });
});
