import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { SelectContractComponent } from '@app-components/select-contract/select-contract.component';
import { PermissionEnum } from '@app-graphql';
import { ContractService } from '@app-services/contract/contract.service';
import { isNotNull } from '@app-tools/is-not-null';
import { any } from 'ramda';
import { filter, firstValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class PermissionGuard  {

    constructor(
        private contractsService: ContractService,
        private router: Router,
    ) {
    }

    public async canActivate(
        route: ActivatedRouteSnapshot,
    ): Promise<boolean | UrlTree> {
        const permissions = route.data.permissions as Array<PermissionEnum>;
        if (! permissions) return true;

        const currentPermissions = await firstValueFrom(this.contractsService.getContractPermissions().pipe(
            filter(isNotNull),
        ));
        const currentRole = await firstValueFrom(this.contractsService.getContractRole().pipe(
            filter(isNotNull),
        ));

        return any((permission) => currentPermissions.includes(permission), permissions) ? true
            : this.router.navigateByUrl(SelectContractComponent.getStartingRoute(currentRole));
    }

}
