import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
    providedIn: 'root',
})
export class AnimationsService {
    private animations: Record<string, Animation> = {};

    public addAnimationAndRefreshAnimations(animation: Animation): string {
        const animationId: string = uuidv4();
        this.animations[animationId] = animation;
        this.refreshAnimations();
        return animationId;
    }

    public deleteAnimation(animationId: string): void {
        delete this.animations[animationId];
    }

    private refreshAnimations(): void {
        Object.values(this.animations).forEach((animation) => {
            animation.cancel();
            animation.play();
        });
    }
}
