const plugin = require('tailwindcss/plugin');
const flattenTheme = require('./flatten-theme');

const webkitBoxShadow = plugin(({ addUtilities, variants, theme, e }) => {
    const flattenColors = flattenTheme(theme('colors'));
    const utilities = Object.entries(flattenColors).reduce((acc, [key, value]) => Object.assign(acc, {
        [`.${e(`webkit-box-shadow-${key}`)}`]: {
            '-webkit-box-shadow': `0 0 0 1000px ${value} inset`,
        },
    }), {});
    addUtilities(utilities, variants('webkitBoxShadow'));
}, {
    variants: { webkitBoxShadow: [] },
});

module.exports = webkitBoxShadow;
