import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, fromEventPattern, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class ConnectionService {
    public speed$: BehaviorSubject<number>;
    public server$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    public isConnected$: Observable<boolean>;

    constructor() {
        const navigator = window.navigator as { [k: string]: any };
        const { connection } = navigator as { connection: NetworkInformation };
        this.speed$ = new BehaviorSubject(connection?.downlink ?? 100);
        if (connection) {
            fromEventPattern<Event>(
                (handler) => connection.addEventListener('change', handler),
                (handler) => connection.removeEventListener('change', handler),
            ).pipe(
                map((event) => (event.currentTarget as NetworkInformation).downlink!),
            ).subscribe((val) => {
                this.speed$.next(val);
            });
        }
        this.isConnected$ = combineLatest([
            this.speed$,
            this.server$,
        ]).pipe(
            map(([speed, server]) => server && speed > 0),
        );
    }
}
