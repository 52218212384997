import { BehaviorSubject, combineLatest } from 'rxjs';

export class ApolloPaginator<T extends string, Y extends string> {
    public first: BehaviorSubject<number>;
    public page: BehaviorSubject<number>;
    public variables$: BehaviorSubject<{ [k in T | Y]: number }>;
    public lastPage: BehaviorSubject<number | undefined>;

    constructor(
        public firstFieldName: T,
        public pageFieldName: Y,
        firstValue: number = 25,
        pageValue: number = 1,
    ) {
        this.first = new BehaviorSubject(firstValue);
        this.page = new BehaviorSubject(pageValue);
        this.lastPage = new BehaviorSubject<number | undefined>(undefined);
        this.variables$ = new BehaviorSubject(this.getVariables());
        combineLatest([
            this.first,
            this.page,
        ]).subscribe(() => {
            this.variables$.next(this.getVariables());
        });
    }

    public setPage(page: number) {
        if (page <= 0 || page > (this.lastPage.getValue() ?? 1000)) {
            return;
        }
        this.page.next(page);
    }

    public setFirst(first: number) {
        this.first.next(first);
    }

    public getVariables() {
        return { ...([
            [
                this.firstFieldName,
                this.first.getValue(),
            ],
            [
                this.pageFieldName,
                this.page.getValue(),
            ],
        ] as [[T, number], [Y, number]]).reduce((acc, [key, value]) => {
            acc[key] = value;
            return acc;
        }, {} as { [k in T | Y]: number }) };
    }
}
