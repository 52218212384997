import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutNewComponent } from '@app-components/layout-new/layout-new.component';
import { SelectContractComponent } from '@app-components/select-contract/select-contract.component';
import { PermissionEnum, RoleEnum } from '@app-graphql';
import { AuthGuard } from '@app-guards/auth/auth.guard';
import { ContractGuard } from '@app-guards/contract/contract.guard';
import { DevelopmentGuard } from '@app-guards/development/development.guard';
import { GuestGuard } from '@app-guards/guest/guest.guard';
import { PermissionGuard } from '@app-guards/permission/permission.guard';
import { RoleGuard } from '@app-guards/role/role.guard';
import { TemporaryPasswordGuard } from '@app-guards/temporary-password/temporary-password.guard';
import { StyleGuideComponent } from './modules/shared/style-guide/style-guide.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'visitors',
        pathMatch: 'full',
    },
    {
        path: 'style-guide',
        canActivate: [DevelopmentGuard],
        component: StyleGuideComponent,
    },
    {
        path: 'auth',
        canActivate: [GuestGuard],
        loadChildren: () => import('./modules/auth/auth.module')
            .then((m) => m.AuthModule),
    },
    {
        path: 'change-temporary-password',
        canActivate: [AuthGuard, TemporaryPasswordGuard],
        loadChildren: () => import('./modules/temporary-password/temporary-password.module')
            .then((m) => m.TemporaryPasswordModule),
    },
    {
        path: 'select-contract',
        canActivate: [AuthGuard, TemporaryPasswordGuard],
        component: SelectContractComponent,
    },
    {
        path: '',
        component: LayoutNewComponent,
        canActivate: [AuthGuard, TemporaryPasswordGuard, ContractGuard],
        children: [
            {
                path: 'profile',
                data: {
                    animation: 'ProfilePage',
                },
                loadChildren: () => import('./modules/profile/profile.module')
                    .then((m) => m.ProfileModule),
            },
            {
                path: 'staff',
                canActivate: [RoleGuard],
                data: {
                    animation: 'StaffPage',
                    roles: [RoleEnum.Admin, RoleEnum.Manager, RoleEnum.OperationsManager],
                },
                loadChildren: () => import('./modules/staff/staff.module')
                    .then((m) => m.StaffModule),
            },
            {
                path: 'queue',
                canActivate: [RoleGuard],
                data: {
                    animation: 'QueuePage',
                    roles: [
                        RoleEnum.Admin,
                        RoleEnum.Supervisor,
                        RoleEnum.Manager,
                        RoleEnum.FloorManager,
                        RoleEnum.OperationsManager,
                    ],
                },
                loadChildren: () => import('./modules/queue/queue.module')
                    .then((m) => m.QueueModule),
            },
            {
                path: 'visitors',
                canActivate: [RoleGuard],
                data: {
                    animation: 'GuestsPage',
                    roles: [
                        RoleEnum.Admin,
                        RoleEnum.Supervisor,
                        RoleEnum.Manager,
                        RoleEnum.FloorManager,
                        RoleEnum.OperationsManager,
                        RoleEnum.ResponsibleGamingOfficer,
                        RoleEnum.Employee,
                    ],
                },
                loadChildren: () => import('./modules/guests/guests.module')
                    .then((m) => m.GuestsModule),
            },
            {
                path: 'safety-net-monitor',
                canActivate: [RoleGuard],
                data: {
                    animation: 'SafetyNetMonitorPAge',
                    roles: [
                        RoleEnum.Admin,
                        RoleEnum.ResponsibleGamingOfficer,
                        RoleEnum.OperationsManager,
                        RoleEnum.Manager,
                    ],
                },
                loadChildren: () => import('./modules/safety-net-monitor/safety-net-monitor.module')
                    .then((m) => m.SafetyNetMonitorModule),
            },
            {
                path: 'safety-notifications',
                canActivate: [RoleGuard],
                data: {
                    animation: 'SafetyNotifications',
                    roles: [
                        RoleEnum.Admin,
                        RoleEnum.Engineer,
                        RoleEnum.FloorManager,
                        RoleEnum.Manager,
                        RoleEnum.OperationsManager,
                        RoleEnum.ResponsibleGamingOfficer,
                        RoleEnum.Supervisor,
                    ],
                },
                loadChildren: () => import('./modules/safety-notifications/safety-notifications.module')
                    .then((m) => m.SafetyNotificationsModule),
            },
            {
                path: 'overview',
                canActivate: [RoleGuard],
                data: {
                    animation: 'ManagementPage',
                    roles: [
                        RoleEnum.Admin,
                        RoleEnum.Supervisor,
                        RoleEnum.Manager,
                        RoleEnum.FloorManager,
                        RoleEnum.OperationsManager,
                        RoleEnum.ResponsibleGamingOfficer,
                    ],
                },
                loadChildren: () => import('./modules/reporting/reporting.module').then((m) => m.ReportingModule),
            },
            {
                path: 'management',
                canActivate: [RoleGuard],
                data: {
                    animation: 'ManagementPage',
                    roles: [RoleEnum.Admin, RoleEnum.Engineer],
                },
                loadChildren: () => import('./modules/management/management.module').then((m) => m.ManagementModule),
            },
            {
                path: 'tasks',
                canActivate: [PermissionGuard],
                data: {
                    animation: 'ManagementPage',
                    permissions: [
                        PermissionEnum.VisitorTaskUpdate,
                        PermissionEnum.VisitorTaskView,
                        PermissionEnum.VisitorTaskViewAny,
                    ],
                },
                loadChildren: () => import('./modules/tasks/tasks.module').then((m) => m.TasksModule),
            },
            {
                path: 'notes',
                canActivate: [RoleGuard],
                data: {
                    roles: [
                        RoleEnum.Admin,
                        RoleEnum.Supervisor,
                        RoleEnum.Manager,
                        RoleEnum.FloorManager,
                        RoleEnum.OperationsManager,
                    ],
                },
                loadChildren: () => import('./modules/notes/notes.module').then((m) => m.NotesModule),
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
