import { Injectable } from '@angular/core';
import { EnvironmentNames } from '@app-interfaces/environment-names';
import { EnvironmentJSON } from '@app-interfaces/environment';

@Injectable({
    providedIn: 'root',
})
export class EnvironmentService {
    public environment: EnvironmentJSON;

    public async initialise() {
        const json = await (await fetch('/assets/environment.json')).json();
        this.environment = json;
        return json;
    }

    public isSirWinston(): boolean {
        if (! this.environment) return false;
        return this.environment.name === EnvironmentNames.sirWinston;
    }

    public isProduction(): boolean {
        if (! this.environment) return false;

        return this.environment.testLogins ? this.environment.testLogins.length > 0 : false;
    }
}
