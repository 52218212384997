import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';

import { TokensService } from '@app-services/tokens/tokens.service';

import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard  {
    constructor(
        private tokensService: TokensService,
        private router: Router,
    ) {
    }

    public canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const tokens = this.tokensService.auth.getValue();
        if (tokens && tokens.token) {
            return true;
        }
        return this.router.navigate(['auth', 'login'], {
            queryParams: {
                redirectUrl: window.location.pathname,
            },
        });
    }

}
