<app-layout-auth>
    <ng-container *ngIf="userService.me$ | async as me; else loading">
        <p class="my-4 text-small text-center">
            Welkom {{ me.firstName }}, selecteer een contract.
        </p>
        <mat-form-field>
            <mat-label>Selecteer contract</mat-label>
            <mat-select [formControl]="contractForm">
                <mat-option [value]="contract.id" *ngFor="let contract of contracts">
                    {{ contract.readableName }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <button (click)="setContract()" class="mt-4" appButton buttonType="solid" color="seafoam"
            [disabled]="! contractForm.valid">
            Doorgaan
        </button>
    </ng-container>
</app-layout-auth>

<ng-template #loading>
    <app-loading></app-loading>
</ng-template>