import { Directive, ElementRef, Renderer2 } from '@angular/core';
import { AnimationsDirective } from '@app-directives/animations/animations.directive';
import { AnimationsService } from '@app-services/animations/animations.service';

@Directive({
    selector: '[appPulseAnimation]',
})
export class PulseAnimationDirective extends AnimationsDirective {

    private divElement: HTMLElement;

    constructor(
        private elementRef: ElementRef,
        private renderer: Renderer2,
        animationsService: AnimationsService,
    ) {
        super(animationsService);
        this.divElement = this.renderer.createElement('div');
        this.renderer.addClass(this.divElement, 'pulse');
        this.animation = this.divElement.animate([
            {
                transform: 'scale(1)',
                opacity: 1,
            },
            {
                transform: 'scale(1.8)',
                opacity: 0,
            },
        ], {
            duration: 1500,
            iterations: Infinity,
        });
        this.renderer.appendChild(this.elementRef.nativeElement, this.divElement);
    }

}
