import { Component, Input } from '@angular/core';
import { TailwindColorGroup } from 'tailwindcss/tailwind-config';

@Component({
    selector: 'app-color-card',
    templateUrl: './color-card.component.html',
    styles: [
        ':host { display: contents }',
    ],
})
export class ColorCardComponent {
    @Input()
    public colorGroup: TailwindColorGroup | string;

    @Input()
    public colorName: string;
}
