import { animate, state, style, transition, trigger } from '@angular/animations';

export const ScaleYFade = trigger('scaleYFade', [
    state('in', style({ transform: 'scaleY(1)', opacity: 1 })),
    transition('void => *', [
        style({ transform: 'scaleY(0)', opacity: 0 }),
        animate('250ms ease-in-out'),
    ]),
    transition('* => void', [
        animate('250ms ease-in-out', style({ transform: 'scaleY(0)', opacity: 0 })),
    ]),
]);
