import { Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import { flatten } from 'ramda';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

export interface ClassArrayConfig {
    classes: Array<string>;
    test: (() => boolean) | boolean;
}

@Directive({
    selector: '[appTailwind]',
})
export class TailwindDirective implements OnDestroy {
    private currentExtraClasses: Set<ClassArrayConfig> = new Set<ClassArrayConfig>();
    private lastAdded: Array<string> = [];
    private applyStyles: Subject<void> = new Subject();
    protected ngDestroy = new Subject<void>();

    @Input()
    public set extraClasses(val: Array<ClassArrayConfig | Array<ClassArrayConfig>>) {
        const rootConfig = flatten(val).filter((c) => !! c);
        rootConfig.forEach((rc) => {
            this.currentExtraClasses.add(rc);
        });
        const classesToAdd = flatten(
            rootConfig.filter(
                (config) => (typeof config.test === 'function' ? config.test() : config.test),
            ).map(
                (config) => config.classes,
            ),
        );
        const classesToRemove = flatten(rootConfig.filter((config) => ! config.test).map(
            (config) => config.classes,
        )).filter((className) => ! classesToAdd.includes(className));
        this.lastAdded = classesToAdd;
        this.el.nativeElement.classList.add(...classesToAdd);
        this.el.nativeElement.classList.remove(...classesToRemove);
    }

    constructor(
        public el: ElementRef,
    ) {
        this.applyStyles.pipe(
            debounceTime(50),
            takeUntil(this.ngDestroy),
        ).subscribe(() => {
            this.el.nativeElement.classList.remove(...this.lastAdded);
            this.extraClasses = Array.from(this.currentExtraClasses);
        });
    }

    public ngOnDestroy() {
        this.ngDestroy.next();
    }

    public reapplyStyles() {
        this.applyStyles.next();
    }
}
