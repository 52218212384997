import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-shadow-card',
    templateUrl: './shadow-card.component.html',
    styles: [':host { display: contents; }'],
})
export class ShadowCardComponent {
    @Input()
    public shadow: string;

    @Input()
    public name: string;
}
