import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { UserService } from '@app-services/user/user.service';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

const changeTemporaryPasswordPageUrl = '/change-temporary-password';

@Injectable({
    providedIn: 'root',
})
export class TemporaryPasswordGuard  {
    constructor(private userService: UserService, private router: Router) {}

    public canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean | UrlTree> {

        const isOnChangeTemporaryPasswordPage = changeTemporaryPasswordPageUrl === state.url;

        return this.userService.me$.pipe(
            map((me) => me?.hasTemporaryPassword || false),
            take(1),
            map((hasTemporaryPassword) => {
                if (isOnChangeTemporaryPasswordPage && ! hasTemporaryPassword) {
                    return this.router.parseUrl('');
                }

                if (isOnChangeTemporaryPasswordPage) return true;
                return hasTemporaryPassword
                    ? this.router.parseUrl(changeTemporaryPasswordPageUrl)
                    : true;
            }),
        );
    }
}
