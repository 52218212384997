<div class="pt-28 rounded flex flex-col shadow"
     [style]="{'background-color': shade.value}"
     *ngFor="let shade of colorGroup | keyvalue"
>
    <div class="bg-white p-2 rounded-b shadow">
        <div class="font-bold mb-4">
            {{ shade.key | titlecase }}
        </div>
        <div>
            <pre class="w-full overflow-hidden overflow-ellipsis whitespace-nowrap">{{ 'bg-' + colorName + (shade.key === 'DEFAULT' ? '' : '-' + shade.key) }}</pre>
            <pre class="w-full overflow-hidden overflow-ellipsis whitespace-nowrap">{{ 'text-' + colorName + (shade.key === 'DEFAULT' ? '' : '-' + shade.key) }}</pre>
        </div>
    </div>
</div>
