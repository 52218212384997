<div *ngIf="! small; else smallLoader"
        class="flex flex-col items-center"
>
    <ng-container [ngTemplateOutlet]="spinner"></ng-container>
    <p class="mt-4 font-bold text-seafoam">{{ message }}</p>
</div>

<ng-template #smallLoader>
    <div class="flex items-center justify-center gap-x-2">
        <ng-container [ngTemplateOutlet]="spinner"></ng-container>
        <p [ngClass]="{ 'hidden lg:block': hideMessageOnSmallVW }" class="font-bold text-seafoam">{{ message }}</p>
    </div>
</ng-template>

<ng-template #spinner>
    <svg [ngClass]="{'w-20': ! small, 'w-8': small }"
         viewBox="0 0 240 240"
         @scaleYFade
    >
        <circle class="stroke-current text-seafoam animate-loaderA"
                cx="120"
                cy="120"
                r="105"
                fill="none"
                stroke-width="20"
                stroke-dasharray="0 660"
                stroke-dashoffset="-330"
                stroke-linecap="round"
        />
        <circle class="stroke-current text-seafoam animate-loaderB"
                cx="120"
                cy="120"
                r="35"
                fill="none"
                stroke-width="20"
                stroke-dasharray="0 220"
                stroke-dashoffset="-110"
                stroke-linecap="round"
        />
        <circle class="stroke-current text-seafoam animate-loaderC"
                cx="85"
                cy="120"
                r="70"
                fill="none"
                stroke-width="20"
                stroke-dasharray="0 440"
                stroke-linecap="round"
        />
        <circle class="stroke-current text-seafoam animate-loaderD"
                cx="155"
                cy="120"
                r="70"
                fill="none"
                stroke-width="20"
                stroke-dasharray="0 440"
                stroke-linecap="round"
        />
    </svg>
</ng-template>
