import { NgModule } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApolloClientOptions, ApolloLink, InMemoryCache, Operation } from '@apollo/client/core';
import { onError } from '@apollo/client/link/error';
import { AlertComponent, AlertDialogData } from '@app-components/alert/alert.component';
import { environment as internalEnvironment } from '@app-environments/environment';
import { ConnectionService } from '@app-services/connection/connection.service';
import { EnvironmentService } from '@app-services/environment/environment.service';
import { LoadingService } from '@app-services/loading/loading.service';
import { TokensService } from '@app-services/tokens/tokens.service';
import { createValidationError, ValidationError } from '@app-tools/create-validation-error';
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { createUploadLink } from 'apollo-upload-client';
import Pusher from 'pusher-js';
import { any } from 'ramda';
import { v4 as uuidv4 } from 'uuid';
import { cacheConfig } from '../../config/cache.config';
import { PusherLink } from '../../links/pusher.link';
import { TimeoutError } from '../../links/timeout-error';
import { TimeoutLink } from '../../links/timeout.link';

export function createApollo(
    tokens: TokensService,
    connection: ConnectionService,
    dialog: MatDialog,
    { environment }: EnvironmentService,
    loadingService: LoadingService,
): ApolloClientOptions<any> {
    const uri = `${environment.api}/graphql`;
    const errorHandler = onError(({ response, graphQLErrors, networkError }) => {
        if (graphQLErrors) {
            graphQLErrors.forEach(({
                message,
                locations,
                path,
                extensions,
            }) => {
                console.error(
                    `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
                );
                if (extensions?.category === 'authentication' && (path as Array<string>)[0] !== 'login') {
                    tokens.setTokens(null);
                    window.location.href = `${window.location.origin}/auth/login?redirectUrl=${encodeURIComponent(window.location.pathname)}`;
                }
                if (extensions?.category === 'authorization') {
                    dialog.open(AlertComponent, {
                        data: {
                            title: 'Deze actie is niet toegestaan',
                            message: 'Je hebt geen toestemming om dit te doen',
                        } as AlertDialogData,
                    });
                }
            });
            if (response && response.extensions) {
                response.extensions.validationErrors = response?.errors?.reduce((acc, err) => {
                    if (err.extensions?.category === 'validation') {
                        acc.push(...createValidationError(err));
                    }
                    return acc;
                }, [] as Array<ValidationError>);
                const hasServerErrors = response?.errors?.reduce((acc, err) => {
                    if (err.extensions?.category === 'internal') {
                        return true;
                    }
                    return acc;
                }, false);
                if (hasServerErrors) {
                    if (! any(((d) => d.componentInstance instanceof AlertComponent), dialog.openDialogs)) {
                        dialog.open(AlertComponent, {
                            data: {
                                title: 'Er is iets fout gegaan',
                                message: 'Er is een probleem met de server, probeer het later opnieuw',
                            } as AlertDialogData,
                        });
                    }
                }
            }
        }
        if (networkError instanceof TimeoutError && networkError.statusCode === 504) {
            dialog.open(AlertComponent, {
                data: {
                    title: 'Er is iets fout gegaan',
                    message: 'Er is een probleem met de server, probeer het later opnieuw',
                } as AlertDialogData,
            });
        }
        if (networkError) {

            if (
                (
                    networkError instanceof DOMException
                    && networkError.code === networkError.ABORT_ERR
                )
            ) {
                return undefined;
            }

            if (
                networkError instanceof TypeError
                && networkError.message === 'Failed to fetch'
            ) {
                if (! any(((d) => d.componentInstance instanceof AlertComponent), dialog.openDialogs)) {
                    dialog.open(AlertComponent, {
                        data: {
                            title: 'Server onbereikbaar',
                            message: 'De server geeft geen reactie. ' +
                                'Check je internet connectie of contacteer de service desk',
                        } as AlertDialogData,
                    });
                }
                return undefined;
            }
            //@ts-ignore
            if (networkError?.statusCode === 403) {
                if (! any(((d) => d.componentInstance instanceof AlertComponent), dialog.openDialogs)) {
                    dialog.open(AlertComponent, {
                        data: {
                            title: 'Deze actie is niet toegestaan',
                            message: 'Je hebt geen toestemming om dit te doen',
                        } as AlertDialogData,
                    });
                }
            } else {
                if (! any(((d) => d.componentInstance instanceof AlertComponent), dialog.openDialogs)) {
                    dialog.open(AlertComponent, {
                        data: {
                            title: 'Er is iets fout gegaan',
                            message: connection.speed$.getValue() === 0
                                ? 'Controleer uw internetverbinding en probeer het opnieuw'
                                : 'Er is een probleem met de verbinding met de server, probeer het later opnieuw',
                        } as AlertDialogData,
                    });
                }
            }
            console.error(`[Network error]: ${networkError}`);
        } else {
            console.error(`[Unhandled error]: ${response}`);
            if (! any(((d) => d.componentInstance instanceof AlertComponent), dialog.openDialogs)) {
                dialog.open(AlertComponent, {
                    data: {
                        title: 'Er is iets fout gegaan',
                        message: 'Er is een probleem met de server, probeer het later opnieuw',
                    } as AlertDialogData,
                });
            }
        }

        return undefined;
    });

    const callCount: { [k: string]: number } = {};
    const devToolsFetch = (url: string, options: RequestInit) => {
        let body: Operation;
        const newOptions: RequestInit = options;
        if (options.body instanceof FormData) {
            body = JSON.parse(options.body.get('operations') as string) as Operation;
        } else if (typeof options.body === 'string') {
            body = JSON.parse(options.body) as Operation;
        } else {
            return fetchWithLoadingService(url, newOptions);
        }
        const { operationName } = body;
        if (! callCount[operationName]) {
            callCount[operationName] = 1;
        } else {
            callCount[operationName] += 1;
        }
        if (newOptions.body instanceof FormData) {
            newOptions.body.append('callCount', callCount[operationName].toString());
        } else {
            newOptions.body = JSON.stringify({
                ...body,
                callCount: callCount[operationName],
            });
        }
        return fetchWithLoadingService(`${url}${internalEnvironment.production ? '' : `?op=${operationName}`}`, newOptions);
    };

    const fetchWithLoadingService = (url: string, options: RequestInit) => {
        const uuid = uuidv4();
        loadingService.addLoadingItem(uuid);
        options.signal?.addEventListener('abort', () => {
            loadingService.removeLoadingItem(uuid);
        });
        return fetch(url, {
            ...options,
        }).then((response) => {
            loadingService.removeLoadingItem(uuid);
            return response;
        });
    };

    const pusherLink = new PusherLink({
        pusher: new Pusher(environment.pusher.key, {
            cluster: environment.pusher.cluster,
            authEndpoint: `${uri}/subscriptions/auth`,
            auth: {
                headers: {
                    authorization: environment.pusher.secret,
                    contract: tokens.currentContractId$.getValue(),
                },
            },
        }),
    });

    const authLink = new ApolloLink((operation, forward) => {
        let context = operation.getContext();
        const currentTokens = tokens.auth.getValue();
        const currentContract = tokens.currentContractId$.getValue();
        context = {
            ...context,
            headers: {
                ...context.headers,
                authorization: currentTokens && currentTokens.token ? `Bearer ${currentTokens.token}` : '',
                'X-Device-Token': '6a80edaa-a0f3-4a4c-8dd6-1710db83c1e2',
            },
        };
        if (currentContract) {
            context.headers.Contract = currentContract;
        }
        operation.setContext(context);
        return forward(operation);
    });

    const connectionLink = new ApolloLink((operation, forward) => forward(operation).map((value) => {
        if (! connection.server$.getValue()) {
            connection.server$.next(true);
        }
        return value;
    }));

    const timeoutLink = new TimeoutLink({
        timeout: 20000,
        statusCode: 504,
        timeoutIf: (operation) => ! any(
            (def) => def.kind === 'OperationDefinition'
                && def.operation === 'mutation', operation.query.definitions,
        ),
    });

    return {
        link: ApolloLink.from([
            pusherLink,
            errorHandler,
            timeoutLink,
            authLink,
            connectionLink,
            createUploadLink({
                uri,
                fetch: internalEnvironment.production ? fetchWithLoadingService : devToolsFetch,
            }),
        ]),
        connectToDevTools: internalEnvironment.production === false,
        cache: new InMemoryCache({
            ...cacheConfig,
        }),
    };
}

@NgModule({
    imports: [ApolloModule],
    providers: [
        {
            provide: APOLLO_OPTIONS,
            useFactory: createApollo,
            deps: [
                TokensService,
                ConnectionService,
                MatDialog,
                EnvironmentService,
                LoadingService,
            ],
        },
    ],
})
export class GraphQLModule { }
