import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { environment } from '@app-environments/environment';

@Injectable({
    providedIn: 'root',
})
export class DevelopmentGuard  {
    constructor(
        private router: Router,
    ) {
    }

    public canActivate(): boolean | UrlTree {
        const isDevelopment = ! environment.production;
        return isDevelopment || this.router.createUrlTree(['']);
    }

}
