import { GraphQLError } from 'graphql';

export interface ValidationError {
    field: string;
    message: Array<string>;
}

export const createValidationError = (error: GraphQLError): ValidationError[] => {
    const validation = error.extensions.validation;
    if (! validation || typeof validation !== 'object') return [];

    const entries = Object.entries(validation);
    return entries.map(([field, message]) => ({
        field: field.replace(/^input\./g, ''),
        message,
    } as ValidationError));
};

export function createValidationErrors(errors: GraphQLError[]): ValidationError[] {
    return errors.reduce((acc, graphqlError) => {
        const validationErrors = createValidationError(graphqlError);
        if (! validationErrors || ! validationErrors.length) return acc;
        return [...acc, ...validationErrors];
    }, [] as ValidationError[]);
}