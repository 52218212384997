import { Platform } from '@angular/cdk/platform';
import { Directive, ElementRef, HostBinding, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import { MatRipple } from '@angular/material/core';
import { always } from 'ramda';
import { BehaviorSubject, concat } from 'rxjs';
import { debounceTime, skip, take, takeUntil } from 'rxjs/operators';
import { TailwindDirective } from '../tailwind.directive';

export type ButtonColors = 'seafoam' | 'danger' | 'gray' | 'success' | undefined;

type ButtonType = 'solid' | 'outline' | 'clear';

@Directive({
    selector: '[appButton]',
})
export class ButtonDirective extends TailwindDirective implements OnInit, OnDestroy {
    private currentColor$: BehaviorSubject<ButtonColors> = new BehaviorSubject<ButtonColors>(undefined);
    private isDisabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private type$: BehaviorSubject<ButtonType> = new BehaviorSubject<ButtonType>('solid');

    @Input()
    public get color() {
        return this.currentColor$.getValue();
    }

    public set color(value: 'seafoam' | 'danger' | 'gray' | 'success' | undefined) {
        this.currentColor$.next(value);
    }

    @Input()
    public get disabled() {
        return this.isDisabled$.getValue();
    }

    public set disabled(value: boolean | undefined) {
        this.isDisabled$.next(value === undefined ? true : value);
    }

    @Input()
    public get buttonType() {
        return this.type$.getValue();
    }

    public set buttonType(value: ButtonType) {
        this.type$.next(value);
    }

    @HostBinding('attr.matRipple')
    public ripple = new MatRipple(this.el, this.ngZone, this.platform);

    constructor(
        public override el: ElementRef,
        public ngZone: NgZone,
        public platform: Platform,
    ) {
        super(el);
        this.extraClasses = [
            {
                classes: [
                    'grid',
                    'grid-flow-col',
                    'auto-cols-auto',
                    'gap-1',
                    'justify-center',
                    'items-center',
                    'whitespace-nowrap',
                    'px-8',
                    'py-2',
                    'rounded-md',
                    'transition',
                    'transition-all',
                    'overflow-hidden',
                    'focus:outline-none',
                    'font-bold',
                    'text-p',
                    'mat-ripple',
                    'border',
                    'border-transparent',
                ],
                test: always(true),
            },
            {
                classes: [
                    'filter',
                    'saturate-25',
                    'brightness-150',
                    'cursor-default',
                ],
                test: () => this.isDisabled$.getValue(),
            },
            {
                classes: ['cursor-pointer'],
                test: () => ! this.disabled,
            },
            {
                classes: [
                    'bg-white',
                    'rounded',
                    'filter',
                    'contrast-100',
                    'hover:contrast-75',
                ],
                test: () => this.buttonType === 'outline',
            },
            {
                classes: [
                    'text-seafoam',
                    'border-seafoam',
                ],
                test: () => this.buttonType === 'outline' && this.color === 'seafoam',
            },
            {
                classes: [
                    'text-danger',
                    'border-danger',
                ],
                test: () => this.buttonType === 'outline' && this.color === 'danger',
            },
            {
                classes: [
                    'text-seafoam-disabled',
                    'border-gray',
                ],
                test: () => this.buttonType === 'outline' && this.color === 'gray',
            },
            {
                classes: [
                    'bg-seafoam',
                    'text-white',
                ],
                test: () => this.buttonType === 'solid' && this.color === 'seafoam',
            },
            {
                classes: [
                    'hover:bg-seafoam-darker',
                ],
                test: () => this.buttonType === 'solid' && this.color === 'seafoam' && ! this.disabled,
            },
            {
                classes: [
                    'bg-danger',
                    'text-white',
                    'hover:bg-danger-darker',
                ],
                test: () => this.buttonType === 'solid' && this.color === 'danger',
            },
            {
                classes: [
                    'bg-gray',
                    'text-white',
                    'hover:bg-gray-darker',
                ],
                test: () => this.buttonType === 'solid' && this.color === 'gray',
            },
            {
                classes: [
                    'px-3',
                    'bg-white',
                    'filter',
                    'contrast-100',
                    'hover:contrast-75',
                ],
                test: () => this.buttonType === 'clear',
            },
            {
                classes: [
                    'text-seafoam',
                    'hover:text-seafoam-darker',
                ],
                test: () => this.buttonType === 'clear' && this.color === 'seafoam',
            },
            {
                classes: [
                    'text-danger',
                    'hover:text-danger-darker',
                ],
                test: () => this.buttonType === 'clear' && this.color === 'danger',
            },
            {
                classes: [
                    'text-gray',
                    'hover:text-gray-darker',
                ],
                test: () => this.buttonType === 'clear' && this.color === 'gray',
            },
            {
                classes: [
                    'text-success',
                    'hover:text-success-darker',
                ],
                test: () => this.buttonType === 'clear' && this.color === 'success',
            },
        ];

        concat(
            this.currentColor$.pipe(take(1)),
            this.currentColor$.pipe(skip(1), debounceTime(100)),
        ).pipe(
            takeUntil(this.ngDestroy),
        ).subscribe(() => {
            this.reapplyStyles();
        });
        concat(
            this.isDisabled$.pipe(take(1)),
            this.isDisabled$.pipe(skip(1), debounceTime(100)),
        ).pipe(
            takeUntil(this.ngDestroy),
        ).subscribe((disabled) => {
            if (disabled) {
                this.el.nativeElement.setAttribute('disabled', true);
            } else {
                this.el.nativeElement.removeAttribute('disabled');
            }
            this.reapplyStyles();
        });
        concat(
            this.type$.pipe(take(1)),
            this.type$.pipe(skip(1), debounceTime(100)),
        ).pipe(
            takeUntil(this.ngDestroy),
        ).subscribe(() => {
            this.reapplyStyles();
        });
    }

    public ngOnInit() {
        this.ripple.ngOnInit();
    }

    public override ngOnDestroy() {
        this.ripple.ngOnDestroy();
    }
}
