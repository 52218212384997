const isObject = (value) => typeof value === 'object';
const isString = (value) => typeof value === 'string';
const isNumber = (value) => typeof value === 'number';

const flattenTheme = (theme, opts = {}) => {
    if (! isObject(theme)) {
        throw new Error('The `theme` field should contains an object.');
    }

    const { prefix = '', separator = '-' } = opts;

    return Object.keys(theme).reduce((acc, key) => {
        const value = theme[key];
        if (isObject(value)) {
            return Object.assign(acc, flattenTheme(value, { prefix: `${prefix}${key}${separator}`, separator }));
        } if (isString(value) || isNumber(value)) {
            const str = `${key === 'DEFAULT' ? prefix.slice(0, prefix.length - 1) : prefix}${key === 'DEFAULT' ? '' : key}`;
            return Object.assign(acc, { [str]: value });
        }
        return acc;

    }, {});
};

module.exports = flattenTheme;
