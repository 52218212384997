import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UnsubscribingComponent } from '@app-components/unsubscribing/unsubscribing.component';
import { environment } from '@app-environments/environment';
import { ContractFragment, PermissionEnum, RoleEnum, UserMeFragment } from '@app-graphql';
import { ConnectionService } from '@app-services/connection/connection.service';
import { TokensService } from '@app-services/tokens/tokens.service';
import { UserService } from '@app-services/user/user.service';
import { isNotNull } from '@app-tools/is-not-null';
import { toLower, toUpper } from 'ramda';
import { firstValueFrom, Observable } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

export interface MenuItem {
    name: string;
    route: string;
    roles?: RoleEnum[];
    badge: Observable<number | undefined>;
    permissions?: PermissionEnum[];
}

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent extends UnsubscribingComponent implements OnInit {
    @Input()
    public menuItems: Array<MenuItem>;

    @Input()
    public me$: Observable<UserMeFragment | undefined>;

    @Output()
    public statusDialog = new EventEmitter<void>();

    public contractForm: FormControl<string | null>;
    public contracts: Array<{ readableName: string; } & ContractFragment>;

    public RoleEnum = RoleEnum;
    public PermissionEnum = PermissionEnum;
    public environment = environment;

    constructor(
        private fb: FormBuilder,
        public userService: UserService,
        private tokensService: TokensService,
        public connectionService: ConnectionService,
        private router: Router,
    ) {
        super();
    }

    public async ngOnInit() {
        this.contractForm = this.fb.control(this.tokensService.currentContractId$.getValue(), [Validators.required]);

        this.contracts = await firstValueFrom(this.userService.me$.pipe(
            filter((me): me is UserMeFragment => !! me),
            map((me) => me.contracts.map((contract) => {
                const [firstRoleLetter, ...roleTextLeft] = toLower(contract.role.name);
                return { ...contract, readableName: `${contract.establishment.name} ${toUpper(firstRoleLetter) + roleTextLeft.join('')}` };
            })),
        ));

        this.contractForm.valueChanges.pipe(
            filter(isNotNull),
            takeUntil(this.ngDestroy$),
        ).subscribe((value) => {
            this.setContract(value);
        });
    }

    public async setContract(contractId: string) {
        this.tokensService.setCurrentContract(contractId);
        const contract = this.contracts.find((c) => c.id === contractId)!;

        await this.router.navigateByUrl(this.getStartingRoute(contract.role.name));
    }

    public getStartingRoute(role: RoleEnum): string {
        const queueRoles = [
            RoleEnum.Admin, RoleEnum.Supervisor, RoleEnum.Manager, RoleEnum.FloorManager, RoleEnum.OperationsManager,
        ];
        if (queueRoles.includes(role)) {
            return '/queue';
        }
        if (role === RoleEnum.Engineer) {
            return '/management';
        }
        return '/profile/user';
    }
}
