import { Injectable } from '@angular/core';
import { EnvironmentService } from '@app-services/environment/environment.service';
import { fromEvent, Observable } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class ActivityMonitorService {
    constructor(private readonly environmentService: EnvironmentService) { }

    public activity$: Observable<Event> = fromEvent(document, 'mousemove').pipe(
        debounceTime((this.environmentService.environment.activityMonitorTimeout || 300) * 1000),
        filter(() => ! window.location.origin.includes('')),
    );
}
