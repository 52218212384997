export const EnvironmentNames = {
    acceptance: 'acceptance',
    avifauna: 'avifauna',
    bigApple: 'big-apple',
    demo: 'demo',
    development: 'development',
    jackpot: 'jackpot',
    numberOne: 'number-one',
    pierreDeJonge: 'pierre-de-jonge',
    players: 'players',
    sirWinston: 'sir-winston',
    tripleSeven: 'triple-seven',
};
