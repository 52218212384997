import { Injectable } from '@angular/core';
import {
    ForgotPasswordMutation,
    ForgotPasswordMutationService,
    ForgotPasswordMutationVariables,
    LoginMutationService,
    LoginMutationVariables,
    LogoutMutationService,
    ResetPasswordMutationService,
    ResetPasswordMutationVariables,
    UpdatePasswordMutationService,
    UpdatePasswordMutationVariables,
} from '@app-graphql';
import { MutationResult } from 'apollo-angular';
import { firstValueFrom } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TokensService } from '../tokens/tokens.service';

@Injectable({
    providedIn: 'root',
})
export class AuthService {

    constructor(
        private loginService: LoginMutationService,
        private updatePasswordService: UpdatePasswordMutationService,
        private forgotPasswordService: ForgotPasswordMutationService,
        private tokensService: TokensService,
        private logoutService: LogoutMutationService,
        private resetPasswordService: ResetPasswordMutationService,
    ) { }

    public async login(input: LoginMutationVariables) {
        const result = await firstValueFrom(this.loginService.mutate(input, { errorPolicy: 'all' }));
        if (result.data?.login) {
            this.tokensService.setTokens(result.data.login);
        }
        return result;
    }

    public forgotPassword(input: ForgotPasswordMutationVariables): Promise<MutationResult<ForgotPasswordMutation>> {
        return firstValueFrom(this.forgotPasswordService.mutate(input, {
            errorPolicy: 'all',
        }));
    }

    public resetPassword(input: ResetPasswordMutationVariables) {
        return firstValueFrom(this.resetPasswordService.mutate(input, {
            errorPolicy: 'all',
        }));
    }

    public updatePassword(input: UpdatePasswordMutationVariables) {
        return firstValueFrom(this.updatePasswordService.mutate(input));
    }

    public logout() {
        return firstValueFrom(this.logoutService.mutate().pipe(
            tap(() => {
                this.tokensService.setTokens(null);
            }),
        ));
    }
}
