<h2 mat-dialog-title
    class="mb-4"
>{{ data.title }}</h2>
<div class="mb-8">
    {{ data.message }}
</div>
<div class="flex justify-end">
    <button appButton
            buttonType="solid"
            color="seafoam"
            mat-dialog-close
    >
        Ok
    </button>
</div>
