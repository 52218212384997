import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class LoadingService {
    private currentlyLoading = new Set<string>();
    private currentlyLoading$ = new BehaviorSubject<string[]>([]);

    public isLoading$: Observable<boolean>;

    constructor() {
        this.isLoading$ = this.currentlyLoading$.pipe(
            map((loading) => !! loading.length),
            distinctUntilChanged(),
            debounceTime(300),
        );
    }

    public addLoadingItem(id: string) {
        this.currentlyLoading.add(id);
        this.currentlyLoading$.next(Array.from(this.currentlyLoading));
    }

    public removeLoadingItem(id: string) {
        this.currentlyLoading.delete(id);
        this.currentlyLoading$.next(Array.from(this.currentlyLoading));
    }
}
