import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MenuItem } from '@app-components/header/header.component';
import { PermissionEnum, RoleEnum } from '@app-graphql';
import { CheckInService } from '@app-services/check-in/check-in.service';
import { ContractService } from '@app-services/contract/contract.service';
import { LoadingService } from '@app-services/loading/loading.service';
import { ReloaderAppService, reloadTimeInMilliseconds } from '@app-services/reloader-app/reloader-app.service';
import { UserService } from '@app-services/user/user.service';
import { addMilliseconds } from 'date-fns';
import { combineLatest, merge, Observable, of } from 'rxjs';
import { delay, map, switchMap, tap } from 'rxjs/operators';
import { TasksService } from 'src/app/modules/tasks/tasks.service';

@Component({
    selector: 'app-layout-new',
    templateUrl: './layout-new.component.html',
    styleUrls: ['./layout-new.component.scss'],
})
export class LayoutNewComponent {
    public menuItems: Array<MenuItem> = [
        {
            name: 'Wachtrij',
            route: '/queue',
            roles: [
                RoleEnum.Admin,
                RoleEnum.Supervisor,
                RoleEnum.Manager,
                RoleEnum.FloorManager,
                RoleEnum.OperationsManager,
            ],
            badge: this.checkInService.queueCount,
        },
        {
            name: 'Gasten',
            route: '/visitors',
            roles: [
                RoleEnum.Admin,
                RoleEnum.Supervisor,
                RoleEnum.Manager,
                RoleEnum.FloorManager,
                RoleEnum.OperationsManager,
                RoleEnum.ResponsibleGamingOfficer,
                RoleEnum.Employee,
            ],
            badge: of(undefined),
        },
        {
            name: 'Taken',
            route: '/tasks',
            permissions: [
                PermissionEnum.VisitorTaskUpdate,
                PermissionEnum.VisitorTaskView,
                PermissionEnum.VisitorTaskViewAny,
            ],
            badge: this.tasksService.getTasksQueueCount(),
        },
        {
            name: 'Overzicht',
            route: '/overview',
            roles: [
                RoleEnum.Admin,
                RoleEnum.Supervisor,
                RoleEnum.Manager,
                RoleEnum.FloorManager,
                RoleEnum.OperationsManager,
                RoleEnum.ResponsibleGamingOfficer,
            ],
            badge: of(undefined),
        },
        {
            name: 'Vangnet monitor',
            route: '/safety-net-monitor',
            roles: [
                RoleEnum.Admin,
                RoleEnum.ResponsibleGamingOfficer,
                RoleEnum.OperationsManager,
                RoleEnum.Manager,
            ],
            badge: of(undefined),
        },
        {
            name: 'Personeel',
            route: '/staff',
            // roles: [],
            roles: [RoleEnum.Admin, RoleEnum.Manager, RoleEnum.OperationsManager],
            badge: of(undefined),
        },
        {
            name: 'Management',
            route: '/management',
            roles: [RoleEnum.Admin, RoleEnum.Engineer],
            badge: of(undefined),
        },
    ];



    public menuItems$: Observable<Array<MenuItem>> = combineLatest([
        this.contractsService.getContractRole(),
        this.contractsService.getContractPermissions(),
    ]).pipe(
        map(([role, permissions]) => this.menuItems.filter(menuItem => {
            if (! menuItem.roles && ! menuItem.permissions) return true;
            if (menuItem.roles && menuItem.roles.includes(role)) return true;
            if (menuItem.permissions) {
                return permissions.some((permission) => menuItem.permissions!.includes(permission));
            }
            return false;
        })),
    );

    @Input()
    public twoColumn: boolean;

    @Input()
    public shadowRight: boolean;

    public refreshingPageTime$: Observable<Date | null>;

    constructor(
        public dialog: MatDialog,
        public userService: UserService,
        private checkInService: CheckInService,
        private contractsService: ContractService,
        public loadingService: LoadingService,
        public tasksService: TasksService,
        private readonly reloaderAppService: ReloaderAppService,
    ) {
        this.setRefreshingPageTime();
    }

    private setRefreshingPageTime(): void {
        this.refreshingPageTime$ = this.reloaderAppService.getAppNeedsToReload$().pipe(
            switchMap(() => merge(
                of(addMilliseconds(new Date(), reloadTimeInMilliseconds)),
                of(null).pipe(delay(reloadTimeInMilliseconds), tap(() => location.reload())),
            )),
        );
    }
}
