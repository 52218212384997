<div class="layout-auth-container h-full">
    <div class="flex flex-col justify-center">
        <div class="shadow-full rounded-2xl border-1 container">
            <div class="p-4 flex flex-col">
                <div class="self-center">
                    <img src="assets/img/ace.png"
                         class="h-32"
                    />
                </div>
                <ng-content></ng-content>
            </div>
        </div>
    </div>
    <div
        *ngIf="isSirWinston"
        class="avg-notitie shadow-full"
    >
        <img src="/assets/img/avg-uitleg.png" />
        <h2>
            Attentie
        </h2>
        <p>
            ACE bevat persoonlijke informatie van gasten en deze informatie is zeer vertrouwelijk van aard.
        </p>
        <p>
            <b>Het is uitdrukkelijk niet toegestaan om:</b>
        </p>
        <ul>
            <li>
                informatie in te zien en/of te gebruiken anders dan in het kader van de werkzaamheden (toezichthouderstaak) ter plaatse in het casino
            </li>
            <li>
                informatie uit het ACE-systeem te halen en elders op te slaan en/of te delen via andere kanalen (zoals bijvoorbeeld Whatsapp) met collega’s of derden
            </li>
            <li>
                gasten gegevens te verstrekken uit ACE. Gasten die een beroep wensen te doen op hun rechten als betrokkenen (AVG) dienen een verzoek in te dienen via privacy&#64;sirwinston.nl
            </li>
        </ul>

        <p><b>Zero-tolerance bij overtreding</b></p>

        <p>
            Bij constatering dat medewerker of enig ander persoon ongeoorloofd toegang heeft verschaft tot ACE of informatie hieruit heeft gedeeld dat kan dat consequenties hebben voor de (arbeids)overeenkomst, waaronder ontslag op staande voet.
            <br />

        </p>
        <p>
        Geheimhouding is van toepassing op straffe van een boete.
        </p>
    </div>
</div>
