import { Injectable } from '@angular/core';
import {
    ActivateUserMutationService,
    ChangePasswordMutationService,
    ChangePasswordMutationVariables,
    CreateUserMutationService,
    DeactivateUserMutationService,
    MeQueryService,
    PermissionEnum,
    QueryUsersOrderByOrderByClause,
    RoleEnum,
    SortOrder,
    UpdateUserContractsMutationService,
    UpdateUserInput,
    UpdateUserMutationService,
    UserContract,
    UserFullFragment,
    UserMeFragment,
    UserOrderByEnum,
    UserQueryService,
    UsersQuery,
    UsersQueryService,
    UsersQueryVariables,
} from '@app-graphql';
import { ContractService } from '@app-services/contract/contract.service';
import { FormsService } from '@app-services/forms/forms.service';
import { TokensService } from '@app-services/tokens/tokens.service';
import { BehaviorSubject, firstValueFrom, Observable, Subject } from 'rxjs';
import {
    debounceTime,
    filter,
    finalize,
    map,
    mergeMap,
    shareReplay,
    switchMap,
    take,
    tap,
} from 'rxjs/operators';

const defaultPageStaffRequest = 1;
const defaultFirstStaffRequest = 10;

@Injectable({
    providedIn: 'root',
})
export class UserService extends FormsService {
    private meSubject: Subject<UserMeFragment | undefined> = new Subject<UserMeFragment | undefined>();
    private permissions$: Observable<PermissionEnum[]>;

    public me$: Observable<UserMeFragment | undefined>;
    public staffRequest$: Observable<UsersQuery>;
    public staffRequestIsLoading$ = new BehaviorSubject<boolean>(false);
    private selectedUser$ = new BehaviorSubject<UserFullFragment | undefined>(
        undefined,
    );
    private staffRequestInput$ = new BehaviorSubject<UsersQueryVariables>({
        first: defaultFirstStaffRequest,
        orderBy: [{ column: UserOrderByEnum.FirstName, order: SortOrder.Asc }],
        page: defaultPageStaffRequest,
        search: '',
        isActive: true,
    });

    constructor(
        private meService: MeQueryService,
        private tokensService: TokensService,
        private contractsService: ContractService,
        private changePasswordService: ChangePasswordMutationService,
        private usersQueryService: UsersQueryService,
        private userQueryService: UserQueryService,
        private deactivateUserMutationService: DeactivateUserMutationService,
        private updateUserMutationService: UpdateUserMutationService,
        private updateUserContractsMutationService: UpdateUserContractsMutationService,
        private createUserMutationService: CreateUserMutationService,
        private activateUserMutationService: ActivateUserMutationService,
    ) {
        super();
        this.permissions$ = this.contractsService.getContractPermissions();
        this.me$ = this.meSubject.asObservable().pipe(shareReplay(1));
        this.tokensService.auth
            .pipe(
                filter((v) => !! v),
                switchMap(() => this.getMe()),
            )
            .subscribe();

        this.createStaffRequest();
    }

    private createStaffRequest() {
        this.staffRequest$ = this.staffRequestInput$.pipe(
            tap(() => {
                this.staffRequestIsLoading$.next(true);
            }),
            debounceTime(300),
            mergeMap((input) => this.getUsers(input)),
            tap(() => {
                this.staffRequestIsLoading$.next(false);
            }),
            finalize(() => {
                this.staffRequestIsLoading$.next(false);
            }),
            shareReplay(),
        );
    }

    public hasPermission(perm: PermissionEnum): Observable<boolean> {
        return this.permissions$.pipe(
            take(1),
            map((permissions) => permissions.includes(perm)),
        );
    }

    private getMe() {
        return this.meService.fetch(undefined, { fetchPolicy: 'network-only' }).pipe(
            tap((result) => {
                if (result.data.me) {
                    this.meSubject.next(result.data.me);
                }
            }),
            switchMap(() => this.contractsService.currentContract$),
        );
    }

    public async refreshMe(): Promise<void> {
        const result = await firstValueFrom(this.meService.fetch(undefined, { fetchPolicy: 'network-only' }));
        if (result?.data?.me) {
            this.meSubject.next(result.data.me);
        }
    }

    public removeMe(): void {
        this.meSubject.next(undefined);
    }

    public changePassword(input: ChangePasswordMutationVariables) {
        return this.changePasswordService.mutate(input);
    }

    public getUsers(userQueryVariables: UsersQueryVariables) {
        return this.usersQueryService
            .fetch(userQueryVariables, { fetchPolicy: 'network-only' })
            .pipe(map((result) => result.data));
    }

    public getUser(id: string) {
        return this.userQueryService
            .fetch({ id }, { fetchPolicy: 'network-only' })
            .pipe(map((result) => result.data));
    }

    public getSelectedUser(): Observable<UserFullFragment | undefined> {
        return this.selectedUser$.asObservable();
    }

    public getCurrentSelectedUser(): UserFullFragment | undefined {
        return this.selectedUser$.value;
    }

    public async setSelectedUser(userId: string): Promise<void> {
        const user = await firstValueFrom(this.getUser(userId));
        this.selectedUser$.next(user?.user || undefined);
    }

    public removeSelectedUser() {
        this.selectedUser$.next(undefined);
    }

    public setStaffPagination({
        page,
        pageSize,
    }: {
        page: number;
        pageSize: number;
    }): void {
        const currentInputStaffRequest = this.getCurrentInputStaffRequest();

        currentInputStaffRequest.first = pageSize;
        currentInputStaffRequest.page = page;

        this.setStaffInputRequest(currentInputStaffRequest);
    }

    public setStaffSearch(search: string): void {
        const currentInputStaffRequest = this.getCurrentInputStaffRequest();
        currentInputStaffRequest.search = search;
        this.setDefaultPagination(currentInputStaffRequest);
        this.setStaffInputRequest(currentInputStaffRequest);
    }

    public getStaffPagination() {
        return this.staffRequestInput$.pipe(
            map((input) => ({ page: input.page, pageSize: input.first })),
        );
    }

    public isStaffLoading(): Observable<boolean> {
        return this.staffRequestIsLoading$.asObservable();
    }

    public deactivateUser(userId: string) {
        return firstValueFrom(this.deactivateUserMutationService
            .mutate({ input: userId }));
    }

    public activateUser(userId: string) {
        return firstValueFrom(this.activateUserMutationService
            .mutate({ input: userId }));
    }

    public async updateUser(input: UpdateUserInput): Promise<void> {
        await firstValueFrom(this.updateUserMutationService.mutate({ input }));
    }

    public async saveUser(userId: string) {
        const forms = this.getForms();
        const userFormValue = forms.userForm.value;

        const input: UpdateUserInput = {
            firstName: userFormValue.firstName,
            lastName: userFormValue.lastName,
            email: userFormValue.email,
            id: userId,
        };
        if (userFormValue.password) input.password = userFormValue.password;
        await Promise.all([this.updateUser(input), this.updateUserEstablishments(userId)]);
    }

    private updateUserEstablishments(userId: string) {
        const forms = this.getForms();
        const userEstablishmentsFormValue: any[] = forms.userEstablishmentsForm?.value?.establishments || [];

        const contracts: UserContract[] = userEstablishmentsFormValue
            .filter((establishment) => establishment.checked)
            .reduce((pv, item) => {
                const newContracts = item.roles.map((role: RoleEnum) => ({
                    role,
                    establishmentId: item.establishment.id,
                }));
                return pv.concat(newContracts);
            }, []);
        return firstValueFrom(this.updateUserContractsMutationService
            .mutate({
                id: userId,
                input: { contracts },
            }),
        );
    }

    public async createNewUser(): Promise<string | void> {
        const forms = this.getForms();
        const userFormValue = forms.userForm.value;

        const user = await firstValueFrom(this.createUserMutationService
            .mutate({
                input: {
                    firstName: userFormValue.firstName,
                    lastName: userFormValue.lastName,
                    email: userFormValue.email,
                    password: userFormValue.password,
                },
            }));

        const userId = user.data?.createUser.id;
        if (! userId) return;
        try {
            await this.updateUserEstablishments(userId);
            return userId;
        } catch (err) {
            return userId;
        }
    }

    public getStaffOrderBy() {
        return this.staffRequestInput$.pipe(
            map((input) => input.orderBy as QueryUsersOrderByOrderByClause[]),
            map((orderBy) => orderBy[0]),
        );
    }

    public getCurrentInputStaffRequest() {
        return this.staffRequestInput$.value;
    }

    public getCurrentStaffOrderBy(): QueryUsersOrderByOrderByClause {
        const orderBy = this.getCurrentInputStaffRequest()
            .orderBy as QueryUsersOrderByOrderByClause[];
        return orderBy[0];
    }

    private setStaffInputRequest(input: UsersQueryVariables) {
        this.staffRequestInput$.next(input);
    }

    private setStaffOrderBy(newOrderBy: QueryUsersOrderByOrderByClause) {
        const currentInputStaffRequest = this.getCurrentInputStaffRequest();
        currentInputStaffRequest.orderBy = [newOrderBy];
        this.setDefaultPagination(currentInputStaffRequest);
        this.setStaffInputRequest(currentInputStaffRequest);
    }

    public updateStaffOrderBy(column: UserOrderByEnum) {
        const currentOrderBy = this.getCurrentStaffOrderBy();
        const isNewColumn = column !== currentOrderBy?.column;

        const order = isNewColumn
            ? SortOrder.Asc
            : this.switchSortOrder(currentOrderBy.order);

        this.setStaffOrderBy({ column, order });
    }

    private switchSortOrder(sortOrder: SortOrder) {
        return sortOrder === SortOrder.Asc ? SortOrder.Desc : SortOrder.Asc;
    }

    public refreshStaffRequest() {
        const currentStaffInput = this.getCurrentInputStaffRequest();
        this.setStaffInputRequest(currentStaffInput);
    }

    public getStaffIsActiveFilter() {
        return this.staffRequestInput$.pipe(map((input) => input.isActive));
    }

    public setStaffIsActiveFilter(isActive: boolean) {
        const currentStaffInput = this.getCurrentInputStaffRequest();
        currentStaffInput.isActive = isActive;
        this.setDefaultPagination(currentStaffInput);
        this.setStaffInputRequest(currentStaffInput);
    }

    private setDefaultPagination(currentStaffInput: UsersQueryVariables): void {
        currentStaffInput.page = defaultPageStaffRequest;
        currentStaffInput.first = defaultFirstStaffRequest;
    }
}
