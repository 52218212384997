import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ContractFragment, RoleEnum, UserMeFragment } from '@app-graphql';
import { TokensService } from '@app-services/tokens/tokens.service';
import { UserService } from '@app-services/user/user.service';
import { toLower, toUpper } from 'ramda';
import { firstValueFrom } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
    selector: 'app-select-contract',
    templateUrl: './select-contract.component.html',
    styleUrls: ['./select-contract.component.scss'],
})
export class SelectContractComponent implements OnInit {
    public contracts: Array<{ readableName: string } & ContractFragment>;
    public contractForm: FormControl<string | null>;

    constructor(
        public userService: UserService,
        private tokensService: TokensService,
        private router: Router,
        private fb: FormBuilder,
    ) { }

    public async ngOnInit(): Promise<void> {
        this.contractForm = this.fb.control(null, [Validators.required]);
        this.contracts = await firstValueFrom(this.userService.me$.pipe(
            filter((me): me is UserMeFragment => !! me),
            filter((me) => me.contracts?.length > 0),
            map((me) => me.contracts.map((contract) => {
                const [firstRoleLetter, ...roleTextLeft] = toLower(contract.role.name);
                const readableName = `${toUpper(firstRoleLetter) + roleTextLeft.join('')} - ${contract.establishment.name}`;
                return { ...contract, readableName };
            })),
        ));

        if (this.contracts.length === 1) {
            this.contractForm.setValue(this.contracts[0].id);
            await this.setContract();
        }
    }

    public async setContract() {
        this.tokensService.setCurrentContract(this.contractForm.value);
        const contract = this.contracts.find((c) => c.id === this.contractForm.value)!;
        const role = contract.role.name;

        await this.router.navigateByUrl(SelectContractComponent.getStartingRoute(role));
    }

    public static getStartingRoute(role: RoleEnum): string {
        const queueRoles = [
            RoleEnum.Admin, RoleEnum.Supervisor, RoleEnum.Manager, RoleEnum.FloorManager, RoleEnum.OperationsManager,
        ];
        if (queueRoles.includes(role)) {
            return '/queue';
        }
        if (role === RoleEnum.Engineer) {
            return '/management';
        }
        return '/profile/user';
    }
}
