import { Component, OnDestroy } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { EnvironmentNames } from '@app-interfaces/environment-names';
import { CountryCode } from '@app-graphql';
import { EnvironmentJSON } from '@app-interfaces/environment';
import { ActivityMonitorService } from '@app-services/activity-monitor/activity-monitor.service';
import { AuthService } from '@app-services/auth/auth.service';
import { ConnectionService } from '@app-services/connection/connection.service';
import { EnvironmentService } from '@app-services/environment/environment.service';
import { TokensService } from '@app-services/tokens/tokens.service';
import * as countries from 'i18n-iso-countries';
import { filter, finalize, switchMap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { ContractService } from '@app-services/contract/contract.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy {
    public subscription$ = new Subscription();

    public loading = true;

    constructor(
        private activityMonitor: ActivityMonitorService,
        private authService: AuthService,
        private router: Router,
        private tokens: TokensService,
        protected connection: ConnectionService,
        private titleService: Title,
        private envService: EnvironmentService,
        private iconsService: MatIconRegistry,
        private domSanitizer: DomSanitizer,
        private contractService: ContractService,
    ) {
        this.handleInitialLoader();
        this.setupIconRegistry();
        this.titleService.setTitle(`Ace - ${this.getTitle(this.envService.environment)}`);
        this.activityMonitor.activity$.pipe(
            filter(() => !! this.tokens.auth.getValue()?.token),
            switchMap(() => this.authService.logout()),
            finalize(() => {
                this.loading = false;
                this.router.navigateByUrl('/auth/login');
            }),
        ).subscribe(() => {
            this.loading = false;
            this.router.navigateByUrl('/auth/login');
        });
    }

    public ngOnDestroy() {
        this.subscription$.unsubscribe();
    }

    public async handleInitialLoader() {
        if (! this.tokens.currentContractId$.getValue()) {
            this.loading = false;
        } else {
            this.subscription$.add(this.contractService.currentContract$.pipe(
                finalize(() => {
                    this.loading = false;
                    this.router.navigateByUrl('/auth/login');
                }),
            ).subscribe(() => {
                this.loading = false;
            }));
        }
    }

    public getTitle(environment: EnvironmentJSON) {
        switch (environment.name) {
            case EnvironmentNames.players:
            case EnvironmentNames.jackpot:
            case EnvironmentNames.avifauna:
            case EnvironmentNames.demo:
            case EnvironmentNames.development:
            case EnvironmentNames.acceptance: {
                const [first, ...rest] = environment.name;
                return `${first.toUpperCase()}${rest.join('')}`;
            }
            case EnvironmentNames.sirWinston:
                return 'Sir Winston';
            case EnvironmentNames.tripleSeven:
                return 'Tripple Seven';
            case EnvironmentNames.pierreDeJonge:
                return 'Pierre de Jonge';
            case EnvironmentNames.bigApple:
                return 'Big Apple';
            default:
                return environment.name;
        }
    }

    private setupIconRegistry() {
        this.iconsService.addSvgIcon(
            'passport',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svg/passport.svg'),
        );
        Object.values(CountryCode).forEach((code) => {
            const alpha2 = countries.alpha3ToAlpha2(code);
            this.iconsService.addSvgIcon(
                code,
                this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/flags/${alpha2.toLowerCase()}.svg`),
            );
        });
    }
}
